import React from 'react'
import Banner from './CompanyPage/Banner'
import { NavBar } from './NavBar'
import MainHero from './CompanyPage/MainHero'
import Team from './CompanyPage/Team'
import Footer from './ui/Footer/FooterCareer'
import { Helmet } from 'react-helmet'

const Company = () => {
  return (
    <div>
        <NavBar/>
      <Banner/>
      <MainHero/>
      <Team/>
      <Footer/>
    </div>
  )
}

export default Company

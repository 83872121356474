import React from 'react'
import { NavBar } from './NavBar'
import Banner from './IndustriesPage/FirstPage/Banner'
import Deliver from './IndustriesPage/FirstPage/Deliver'
import Sectors from './IndustriesPage/FirstPage/Sectors'
import Footer from './ui/Footer/Footer'
import Grow from './IndustriesPage/FirstPage/Grow'

const Industries = () => {
  return (
    <div>
      <NavBar/>
      <Banner/>
      <Deliver/>
      <Sectors/>
      <Grow/>
     <Footer/>
    </div>
  )
}

export default Industries

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Arrow from '../../../src/assets/svg/Arrow 2.svg';
import axios from 'axios';
import EmptyState from '../../components/ui/EmptyState/EmptyState';
import { useNavigate } from 'react-router-dom';
import Footer from '../ui/Footer/FooterCareer';

const displayParentCategory = {
  research_and_development: 'Research And Development',
  sales_and_marketing: 'Sales And Marketing',
  product_and_design: 'Product And Design',
};


const capitalizeWords = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const formatDateRelativeToNow = (utcDate) => {
  const date = new Date(utcDate);
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + timezoneOffset);
  const timeDifference = new Date().getTime() - date.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (daysDifference === 0) {
    return 'Posted Today';
  } else if (daysDifference === 1) {
    return 'Posted Yesterday';
  } else {
    return `Posted ${daysDifference} days ago`;
  }
};

const JobOpportunities = () => {
  const { category } = useParams();
  const [selectedJobCategory, setSelectedJobCategory] = useState(category);
  const [selectedJob, setSelectedJob] = useState('');

  const [jobRolesList, setJobRolesList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);

  const [selectedRole, setSelectedRole] = useState('');
  const [selectedExperience, setSelectedExperience] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [jobListings, setJobListings] = useState([]);
  const [noJobsFound, setNoJobsFound] = useState(true);
  const navigate = useNavigate();

  const handleOpeningsClick = (category) => {
    navigate(`/Careers/jobs/${displayParentCategory[category]}/cv`);
  };
  

  const handleSearch = () => {
    console.log('Search API call');
  };

  const handleJobCategoryChange = (event) => {
    const newJobCategory = event.target.value;
    setSelectedJobCategory(newJobCategory);
    setSelectedRole('');
    setSelectedExperience('');
    setSelectedLocation('');
    getJobList(newJobCategory);
    getDynamicFilterOptions(newJobCategory);
  };

  const handleRoleChange = (event) => {
    const newJobRole = event.target.value;
    setSelectedRole(newJobRole);
    getJobList(
      selectedJobCategory,
      newJobRole,
      selectedExperience,
      selectedLocation
    );
  };

  const handleExperienceChange = (event) => {
    const newExperience = event.target.value;
    setSelectedExperience(newExperience);
    getJobList(
      selectedJobCategory,
      selectedRole,
      newExperience,
      selectedLocation
    );
  };

  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    setSelectedLocation(newLocation);
    getJobList(
      selectedJobCategory,
      selectedRole,
      selectedExperience,
      newLocation
    );
  };

  const getJobList = async (
    category,
    name = '',
    experience = '',
    location = ''
  ) => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/carrers/jobOpportunity?parentCategory=${category}&name=${name}&experience=${experience}&location=${location}`,
      headers: {},
    };
    try {
      const response = await axios.request(config);
      console.log(response.data.data.jobs);
      if (response.data.data.jobs.length === 0) {
        setNoJobsFound(true);
      } else {
        setNoJobsFound(false);
        setJobListings(response.data.data.jobs);
        setSelectedJob(response.data.data.jobs[0]);
      }
    } catch (err) {
      console.log(err);
      setNoJobsFound(true);
    }
  };

  const getDynamicFilterOptions = async (category) => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/carrers/jobOpportunity/filteredValues?parentCategory=${category}`,
      headers: {},
    };
    try {
      const response = await axios.request(config);
      setJobRolesList(response.data.data.uniqueRolesFilter);
      setExperienceList(response.data.data.uniqueExperiencesFilter);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getJobList(category);
    getDynamicFilterOptions(category);
  }, [category]);

  return (
    <>
    <div className='container mx-auto px-4 py-8 font-roboto'>
    <div>
      <h1 className="text-6xl font-bold text-black mb-8 text-center">
        Job Opportunities
      </h1>
      <div className="mb-8 flex items-center space-x-4 justify-center">
        <input
          type="text"
          placeholder="Search a department, designation or keyword"
          className="w-full px-4 py-2 border border-black rounded-md text-black"
          style={{ width: '70%' }}
        />
        <button
          onClick={handleSearch}
          className="px-4 py-2 bg-gray-800 text-white rounded-md"
        >
          Search
        </button>
      </div>
      <div className="mb-6 flex items-center space-x-4 justify-center">
        <button
          onClick={handleSearch}
          className="px-4 py-2 bg-gray-800 text-white rounded-md"
          style={{ width: '18%' }}
        >
          Filter
        </button>
        <select
          onChange={handleJobCategoryChange}
          value={selectedJobCategory}
          className="px-4 py-2 border border-black rounded-md text-gray-700"
          style={{ width: '30%' }}
        >
          <option value="" disabled>
            Job Category
          </option>
          <option value="sales_and_marketing">Sales and Marketing</option>
          <option value="research_and_development">
            Research and Development
          </option>
          <option value="product_and_design">Product and Design</option>
        </select>
        <select
          onChange={handleRoleChange}
          value={selectedRole}
          className="px-4 py-2 border border-black rounded-md text-gray-700"
          style={{ width: '20%' }}
        >
          <option value="" disabled>
            Job Role
          </option>
          {jobRolesList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          onChange={handleExperienceChange}
          value={selectedExperience}
          className="px-4 py-2 border border-black rounded-md text-gray-700"
          style={{ width: '20%' }}
        >
          <option value="" disabled>
            Experience
          </option>
          {experienceList.map((option) => (
            <option key={option} value={option}>
              {option === 0 ? 'Fresher' : `At least ${option} Years`}
            </option>
          ))}
        </select>
        <select
          onChange={handleLocationChange}
          value={selectedLocation}
          className="px-4 py-2 border border-black rounded-md text-gray-700"
          style={{ width: '20%' }}
        >
          <option value="" disabled>
            Location
          </option>
          <option value="offline">Offline</option>
          <option value="remote">Remote</option>
        </select>
      </div>
      {noJobsFound ? (
        <div>
          <EmptyState />
        </div>
      ) : (
        <div className="mt-12 flex">
          <div className="pr-4 text-black">
            {jobListings.map((job) => (
              <div
                key={job.id}
                className="mb-4 p-4 border border-black rounded-md cursor-pointer flex justify-between items-center"
                style={{ width: '411px', height: '130px' }}
                onClick={() => setSelectedJob(job)}
              >
                <div className="flex flex-col w-full">
                  <div className="flex justify-between items-center mb-2 pt-2 mt-4">
                    <p className="text-sm text-[#68B24C] m-0">
                      {formatDateRelativeToNow(job.postedAt)}
                    </p>
                    <button className="flex items-center p-0">
                      <img src={Arrow} className="ml-2 w-4 h-auto" />
                    </button>
                  </div>
                  <h2 className="text-2xl font-bold mb-1 mt-2">
                    {capitalizeWords(job.name)}
                  </h2>
                  <div className="flex items-center justify-between">
                    <p className="text-sm">
                      {displayParentCategory[job.parentCategory]}
                    </p>
                    <p className="text-sm text-gray-500">
                      {capitalizeWords(job.location)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pl-4 text-black">
            {selectedJob && (
              <div
                className="p-4 border border-black rounded-md"
                style={{ width: '628px' }}
              >
                <div className="flex justify-between items-center px-1">
                  <h2 className="text-2xl font-bold">
                    {capitalizeWords(selectedJob.name)}
                  </h2>
                  <p className="text-base text-[#68B24C]">
                    {formatDateRelativeToNow(selectedJob.postedAt)}
                  </p>
                </div>
                <div className="flex justify-between items-center mt-1 px-1">
                  <p className="text-base">
                    Experience:{' '}
                    <span className="font-bold">
                      {selectedJob.experience === 0
                        ? 'Fresher'
                        : `${selectedJob.experience} Years`}
                    </span>
                  </p>
                  <p className="text-base">
                    Start from:{' '}
                    <span className="font-bold">
                      {selectedJob.startFrom} Month
                    </span>
                  </p>
                </div>
                <div className="mt-2">
                  <button onClick={() => handleOpeningsClick(category)} className="px-4 py-2 bg-gray-800 text-white rounded-md mr-2">
                    Apply Now
                  </button>
                  <a href="" className="text-[#07ABDD] underline mr-4">
                    Contact Us
                  </a>
                </div>
                <h3 className="mt-6 text-xl font-bold">JOB DESCRIPTION</h3>
                <ul className="list-disc pl-4 mt-2 text-sm">
                  {selectedJob.jobDescription.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
                <h3 className="mt-6 text-xl font-bold">GOOD TO HAVE</h3>
                <ul className="list-disc pl-4 mt-2 text-sm">
                  {selectedJob.goodToHave.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
    
    </div>
    <Footer/>
    </>
  );
};

export default JobOpportunities;

import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo3.png';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router, Link } from "react-router-dom";
import './NavBar.css';


export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="PEMS Digital" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/" className={activeLink === 'Home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Home')}>Home</Nav.Link>
            <Nav.Link href="/Products" className={activeLink === 'Products' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Products')}>Products</Nav.Link>
            <Nav.Link href="/Industries" className={activeLink === 'Industries' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Industries')}>Industries</Nav.Link>
            <Nav.Link href="/Company" className={activeLink === 'Company' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Company')}>Company</Nav.Link>
            <Nav.Link href="/News-&-Insights" className={activeLink === 'News & Insights' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('News & Insights')}>News & Insights</Nav.Link>
            <Nav.Link href="/Services" className={activeLink === 'Services' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Services')}>Services</Nav.Link>
            <Nav.Link href="/Careers" className={activeLink === 'Careers' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Careers')}>Careers</Nav.Link>
          </Nav>
          <span className="navbar-text">
            <HashLink to='/Contact'>
              <button className="vvd"><span>Get a Quote</span></button>
            </HashLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

import React, { useState } from 'react';
import img1 from '../../assets/PNG/Collaborative.png';
import img2 from '../../assets/PNG/Diverse.png';
import img3 from '../../assets/PNG/Growth.png';
import img4 from '../../assets/PNG/Impactful.png';
import img5 from '../../assets/PNG/Innovative.png';

const WhyWorkWithUs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    'Innovative Culture',
    'Growth Opportunities',
    'Collaborative Environment',
    'Impactful Work',
    'Diverse and Inclusive',
  ];

  const tabContent = [
    {
      text: 'At PEMS Digital, we pride ourselves on fostering an innovative culture where creativity and ingenuity are encouraged and celebrated. Our team constantly pushes the boundaries of what\'s possible, leveraging cutting-edge technology and groundbreaking approaches to solve complex problems. Innovation thrives in an environment where every voice is heard, and we actively seek out diverse perspectives to drive our projects forward. Join us, and be part of a dynamic community that is shaping the future of our industry.',
      imgSrc: img5,
      imgAlt: 'Innovative Culture',
    },
    {
      text: 'At PEMS Digital, your professional development is our priority. We offer a wealth of growth opportunities designed to help you reach your full potential. From comprehensive training programs and leadership development initiatives to mentoring and continuous learning resources, we invest in your career advancement. Our commitment to your growth is reflected in our internal promotion practices and the diverse career paths available within the company. With us, you can build a rewarding career tailored to your aspirations and strengths.',
      imgSrc: img3,
      imgAlt: 'Growth Opportunities',
    },
    {
      text: 'Collaboration is at the heart of everything we do at PEMS Digital. We foster a supportive and inclusive environment where teamwork and mutual respect drive our success. Our open office layout and collaborative tools ensure that ideas flow freely across teams, allowing us to tackle challenges together and achieve collective goals. Whether you\'re working on cross-functional projects or brainstorming in one of our many collaborative spaces, you\'ll find that this is a place where cooperation thrive.',
      imgSrc: img1,
      imgAlt: 'Collaborative Environment',
    },
    {
      text: 'At PEMS Digital, the work you do matters. Our projects are designed to make a significant impact on our clients and the broader community. From innovative solutions that drive business success to initiatives that promote sustainability and social responsibility, our work is both challenging and meaningful. You\'ll have the opportunity to tackle real-world problems and see the tangible results of your efforts. Join us, and contribute to work that truly makes a difference.',
      imgSrc: img4,
      imgAlt: 'Impactful Work',
    },
    {
      text: 'Diversity and inclusion are core values at PEMS Digital. We believe that a diverse workforce brings a wealth of perspectives and ideas that drive innovation and excellence. Our inclusive policies and practices ensure that everyone feels valued and empowered to contribute their best. We are committed to creating a workplace where differences are celebrated and everyone has equal opportunities to succeed. You will be part of a diverse and welcoming community that respects and embraces individuality.',
      imgSrc: img2,
      imgAlt: 'Diverse and Inclusive',
    },
  ];

  return (
    <div className="bg-[#11222C] min-h-screen flex flex-col items-center justify-center p-12">
      <div className="w-full">
        <h2 className="text-white text-6xl font-roboto font-semibold break-words mb-8 text-start px-6">Why Work With Us?</h2>
        <div className="flex justify-center mb-8 space-x-2 overflow-x-auto" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
          {tabs.map((tab, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div className={`w-4 h-4 rounded-full ${activeTab === index ? 'bg-[#07ABDD]' : 'bg-white'}`}></div>
              <div
                className={`cursor-pointer px-2 py-2 border-b-4 ${activeTab === index ? 'border-white text-white' : 'border-transparent text-[#708090]'} hover:border-white transition duration-300 whitespace-nowrap`}
                onClick={() => setActiveTab(index)}
                style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, textTransform: 'uppercase', wordWrap: 'break-word' }}
              >
                {tab}
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center">
          <button className="text-white text-4xl mr-4" onClick={() => setActiveTab((activeTab - 1 + tabs.length) % tabs.length)}>
            &lt;
          </button>
          <div className="flex-1 text-start w-full">
            <div className="flex w-full">
              <div className="w-1/2 flex items-start justify-center p-4">
                <div style={{ width: '100%', color: 'white', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
                  {tabContent[activeTab].text}
                </div>
              </div>
              <div className="w-1/2 flex items-center justify-center p-4">
                <img src={tabContent[activeTab].imgSrc} alt={tabContent[activeTab].imgAlt} className="mx-auto" />
              </div>
            </div>
          </div>
          <button className="text-white text-4xl ml-4" onClick={() => setActiveTab((activeTab + 1) % tabs.length)}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhyWorkWithUs;

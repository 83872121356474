// import React from 'react';
// import { NavBar } from './NavBar'; // Adjust the import as per your file structure
// import Footer from './ui/Footer/Footer'; // Adjust the import as per your file structure
// import privacy from '../assets/PNG/Privacy.png'


// const PrivacyPolicy = () => {
//   return (
//     <div>
//       <NavBar />
//       <div className='p-6'>
//       <div className="max-w-7xl mx-auto mt-24 p-8">
//         <header className="text-center mb-8">
//           <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
//           <div className="relative">
//             <img
//               src={privacy} // Replace with the actual image path
//               alt="Privacy"
//               className="w-full h-auto object-cover"
//             />
//             {/* <div className="absolute inset-0 flex items-center justify-center">
//               <div className="bg-white p-4 rounded shadow-lg">
//                 <p className="text-lg font-semibold">Your data is safe with us</p>
//               </div>
//             </div> */}
//           </div>
//         </header>

//         <div className="space-y-8">
//           <section>
//             <h2 className="text-2xl font-semibold mb-4">1. Sed ut perspiciatis unde omnis?</h2>
//             <p className="text-gray-600">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
//             </p>
//           </section>

//           <section>
//             <h2 className="text-2xl font-semibold mb-4">2. Sed ut perspiciatis unde omnis?</h2>
//             <p className="text-gray-600">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
//             </p>
//           </section>

//           <section>
//             <h2 className="text-2xl font-semibold mb-4">3. Sed ut perspiciatis unde omnis?</h2>
//             <p className="text-gray-600">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
//             </p>
//           </section>

//           <section>
//             <table className="min-w-full bg-white border-collapse">
//               <thead>
//                 <tr>
//                   <th className="border-b px-4 py-2 text-left bg-gradient-to-r from-blue-400 to-green-100">Purpose</th>
//                   <th className="border-b px-4 py-2 text-left bg-gradient-to-r from-green-100 to-green-400">Legal Basis</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="border-b px-4 py-2">
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   </td>
//                   <td className="border-b px-4 py-2">
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="border-b px-4 py-2">
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   </td>
//                   <td className="border-b px-4 py-2">
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="border-b px-4 py-2">
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   </td>
//                   <td className="border-b px-4 py-2">
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </section>
//         </div>
//       </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default PrivacyPolicy;



import React from 'react';
import { NavBar } from './NavBar'; // Adjust the import as per your file structure
import Footer from './ui/Footer/Footer'; // Adjust the import as per your file structure
import privacy from '../assets/PNG/Privacy.png';

const PrivacyPolicy = () => {
  return (
    <div>
      <NavBar />
      <div className='p-6'>
        <div className="max-w-7xl mx-auto mt-24 p-8">
          <header className="text-center mb-8">
            <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
            <div className="relative">
              <img
                src={privacy} // Replace with the actual image path
                alt="Privacy"
                className="w-full h-auto object-cover"
              />
              {/* <div className="absolute inset-0 flex items-center justify-center">
                <div className="bg-white p-4 rounded shadow-lg">
                  <p className="text-lg font-semibold">Your data is safe with us</p>
                </div>
              </div> */}
            </div>
          </header>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold mb-4">INTRODUCTION</h2>
              <p className="text-gray-600">
                PEMS Digital Technologies is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.pemsdigital.com, use our services, or interact with us. Please read this Privacy Policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site or use our services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">INFORMATION WE COLLECT</h2>
              <p className="text-gray-600">
                We may collect information about you in a variety of ways. The information we may collect on the Site includes:
              </p>
              <ul className="list-disc ml-6">
                <li>
                  <strong>Personal Data:</strong> Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.
                </li>
                <li>
                  <strong>Derivative Data:</strong> Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
                </li>
                <li>
                  <strong>Financial Data:</strong> Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
                </li>
                <li>
                  <strong>Data From Social Networks:</strong> User information from social networking sites, such as Facebook, Google+, Instagram, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks.
                </li>
                <li>
                  <strong>Mobile Device Data:</strong> Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.
                </li>
                <li>
                  <strong>Third-Party Data:</strong> Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Site permission to access this information.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">USE OF YOUR INFORMATION</h2>
              <p className="text-gray-600">
                Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
              </p>
              <ul className="list-disc ml-6">
                <li>Create and manage your account.</li>
                <li>Process your transactions and send you related information, including purchase confirmations and invoices.</li>
                <li>Provide, operate, and maintain our services.</li>
                <li>Improve, personalize, and expand our services.</li>
                <li>Understand and analyze how you use our services.</li>
                <li>Develop new products, services, features, etc.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">SHARING YOUR INFORMATION</h2>
              <p className="text-gray-600">
                We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
              </p>
              <ul className="list-disc ml-6">
                <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.</li>
                <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
                <li><strong>Marketing Communications:</strong> With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</li>
                <li><strong>Interactions with Other Users:</strong> If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, and following blogs.</li>
                <li><strong>Online Postings:</strong> When you post comments, contributions, or other content to the Site, your posts may be viewed by all users and may be publicly distributed outside the Site in perpetuity.</li>
                <li><strong>Third-Party Advertisers:</strong> We may use third-party advertising companies to serve ads when you visit the Site. These companies may use information about your visits to the Site and other websites that are contained in web cookies to provide advertisements about goods and services of interest to you.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">Security of Your Information</h2>
              <p className="text-gray-600">
                We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">Policy for Children</h2>
              <p className="text-gray-600">
                We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at contact@pemsdigital.com.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
              <p className="text-gray-600">
                Most web browsers and some mobile operating systems include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">OPTIONS REGARDING YOUR INFORMATION</h2>
              <p className="text-gray-600">
                <strong>Account Information:</strong> You may at any time review or change the information in your account or terminate your account by:
              </p>
              <ul className="list-disc ml-6">
                <li>Logging into your account settings and updating your account.</li>
                <li>Contacting us using the contact information provided below.</li>
              </ul>
              <p className="text-gray-600">
                Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use, and/or comply with legal requirements.
              </p>
              <p className="text-gray-600">
                <strong>Emails and Communications:</strong> If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
              </p>
              <ul className="list-disc ml-6">
                <li>Noting your preferences at the time you register your account with the Site.</li>
                <li>Logging into your account settings and updating your preferences.</li>
                <li>Contacting us using the contact information provided below.</li>
              </ul>
              <p className="text-gray-600">
                If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">CONTACT US</h2>
              <p className="text-gray-600">
                If you have questions or comments about this Privacy Policy, please contact us at:
              </p>
              <ul className="list-disc ml-6">
                <li><strong>PEMS Digital Technologies, SBC 13, -2 floor, thejaswini building, technopark phase 1, kazhakootam, 695582</strong></li>
                <li>Email: info@pemsdigital.com</li>
                <li>Phone: +91 9847333878</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react'
import Banner from './ProductPage/Banner'
import { NavBar } from './NavBar'
import Needs from './ProductPage/Needs'
import Why from './ProductPage/Why'
import Offer from './ProductPage/Offer'
import Building from './ProductPage/Building'
import Partner from './ProductPage/Partner'
import Footer from './ui/Footer/FooterProduct'
import Faq from './Faq/ProductFaq'


const Products = () => {
  return (
    <div>
    <NavBar/>
      <Banner/>
      <Needs/>
      <Why/>
      <Offer/>
      <Building/>
      <Faq/>
      <Partner/>
      <Footer/>
    </div>
  )
}

export default Products

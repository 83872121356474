import React, { useState, useEffect } from 'react';
import svg1 from '../../assets/svg/Service 1.svg';
import svg2 from '../../assets/svg/Service 2.svg';
import svg3 from '../../assets/svg/Service 3.svg';
import svg4 from '../../assets/svg/Service 4.svg';
import svg5 from '../../assets/svg/Service 5.svg';
import svg6 from '../../assets/svg/Service 6.svg';

const services = [
  {
    id: 1,
    icon: svg1,
    title: 'Project Management',
    description: 'This includes tools for planning, scheduling tasks and resources, delegating work, tracking progress, and ensuring projects stay on deadline and within budget.',
  },
  {
    id: 2,
    icon: svg2,
    title: 'Document Management',
    description: 'Provides a central location to store and access crucial documents like blueprints, contracts, permits, and other project files.',
  },
  {
    id: 3,
    icon: svg3,
    title: 'Cost Control',
    description: 'Tracks expenses, helps create budgets, and identifies areas where costs can be optimized.',
  },
  {
    id: 4,
    icon: svg4,
    title: 'Communication and Collaboration',
    description: 'Provides features for efficient communication between team members, stakeholders, and clients. This can include messaging tools, file sharing, and real-time project updates.',
  },
  {
    id: 5,
    icon: svg5,
    title: 'Building Information Modeling (BIM)',
    description: 'Software for creating and managing 3D models of buildings, which can improve collaboration, identify potential issues early on, and optimize design.',
  },
  {
    id: 6,
    icon: svg6,
    title: 'Construction Accounting Software',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

const Services = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? (
    <div className="text-white py-8 px-4" style={{ fontFamily: 'Roboto', background: '#11222C', color: 'white' }}>
      <div className="text-center">
        <h2 className="mb-4" style={{ fontSize: "36px", fontFamily: "Roboto, sans-serif", fontWeight: 600 }}>
          Services
        </h2>
        <p className="mb-8">Leading digital transformation services tailored for construction and infrastructure sectors.</p>
        <div className="flex flex-col gap-8">
          {services.map(service => (
            <div key={service.id} className="flex flex-col items-start">
              <div className="flex flex-row items-center mb-4">
                <img src={service.icon} alt={service.title} className="w-10 h-10 mr-3" />
                <h3 className="mb-2" style={{ color: 'white', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 600 }}>
                  {service.title}
                </h3>
              </div>
              <p className="text-gray-300" style={{ color: 'white', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '22px' }}>
                {service.description}
              </p>
            </div>
          ))}
        </div>
        <button className="mt-8 px-6 py-2 bg-gray-700 rounded-full text-white hover:bg-gray-600 transition duration-300">
          Know More
        </button>
      </div>
    </div>
  ) : (
    <div className=" text-white py-12" style={{fontFamily: 'Roboto', background: '#11222C', color: 'white' }}>
      <div className="text-center">
      <h2 className=" mb-4" style={{ fontSize: "52px", fontFamily: "Roboto, sans-serif", fontWeight: 600 }}>
  Services
</h2>


        <p className="mb-12">Leading digital transformation services tailored for construction and infrastructure sectors.</p>
        <div className="flex flex-wrap justify-center gap-16">
          {services.map(service => (
            <div key={service.id} className="flex flex-col items-start w-full  md:w-1/4">
              <div className="flex flex-row items-start text-start mb-4">
                <img src={service.icon} alt={service.title} className="w-12 h-12 mr-4" />
                <h3 className="mb-2" style={{ width: '100%', color: 'white', fontSize: '24px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word' }}>
                  {service.title}
                </h3>
              </div>
              <p className="text-gray-300 text-start" style={{ width: '100%', color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
                {service.description}
              </p>
            </div>
          ))}
        </div>
        <button className="mt-12 px-8 py-3 bg-gray-700 rounded-full text-white hover:bg-gray-600 transition duration-300">
          Know More
        </button>
      </div>
    </div>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import gif1 from '../../assets/GIF/Digital Transformation Partner.gif';
import gif2 from '../../assets/GIF/Change Management Expert.gif';
import gif3 from '../../assets/GIF/Protection Against Supply Chain Issues.gif';
import gif4 from '../../assets/GIF/Automate Routine Tasks.gif';
import circlesvg from '../../assets/svg/Whychooseusbottomrightcircles.svg';

const FeatureCard = ({ heading, content, imgSrc, isMobile }) => {
  return (
    <div className={`rounded overflow-hidden bg-white border border-gray-200 p-4 ${isMobile ? 'm-2 w-full' : 'max-w-sm p-6 m-4'}`}>
      <div className="w-full flex items-center justify-center">
        <img src={imgSrc} alt={heading} className="object-contain" style={{ width: isMobile ? '80px' : '100px', height: isMobile ? '80px' : '100px' }} />
      </div>
      <div className="py-4">
        <div className={`text-black ${isMobile ? 'text-xl' : 'text-2xl'} font-medium leading-10`}>{heading}</div>
        <div className="py-2">
          <p className="text-gray-700 text-base">{content}</p>
        </div>
      </div>
    </div>
  );
};

const FeaturesGrid = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const contents = [
    "PEMS Digital is your unwavering ally in the digital age, expertly guiding your business through every twist and turn of the digital landscape. With our unparalleled expertise and innovative solutions, we ensure your organization not only adapts but excels in the digital world.",
    "At PEMS Digital, we are the masterminds behind seamless change management. Our meticulously crafted strategies ensure your team not only survives but thrives amidst digital transformation, embracing change with confidence and enthusiasm.",
    "We provide rock-solid solutions to safeguard your business from any supply chain disruptions. With PEMS Digital, you can operate with peace of mind, knowing that your operations will continue uninterrupted, no matter what challenges arise.",
    "PEMS Digital takes the mundane out of your everyday tasks through cutting-edge automation. This frees up your valuable resources, empowering your team to channel their energy into groundbreaking projects and drive the innovation that sets your business apart."
  ];

  const gifs = [gif1, gif2, gif3, gif4];

  return (
    <div className="relative p-6">
      <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
        <div className={`rounded ${isMobile ? 'p-4 m-2' : 'max-w-sm p-6 m-4'} flex-1`}>
          <div className={`text-black ${isMobile ? 'text-xl' : 'text-2xl'} font-normal leading-6 flex items-center`} style={{ marginBottom: isMobile ? '24px' : '74px' }}>
            <div style={{ background: '#2B3E48', borderRadius: '50%', width: '20px', height: '20px', marginRight: '8px' }}></div>
            Why choose us?
          </div>
          <div style={{ width: '100%' }}>
            <span style={{
              background: 'linear-gradient(to right, #07ABDD, #68B24C)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontFamily: 'Roboto',
              textTransform: 'capitalize',
              wordWrap: 'break-word',
              fontSize: isMobile ? '32px' : '40px',
              fontWeight: 600
            }}>
              Automate, Adapt,
              <span style={{
                background: 'linear-gradient(to right, #07ABDD, #68B24C)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: 'Roboto',
                textTransform: 'capitalize',
                wordWrap: 'break-word',
                fontSize: isMobile ? '32px' : '40px',
                fontWeight: 600
              }}> Accelerate: <br /></span>
            </span>
            <span style={{
              color: '#2D2D2D',
              fontSize: isMobile ? '14px' : '16px',
              fontFamily: 'Roboto',
              fontWeight: 400,
              lineHeight: isMobile ? '24px' : '26px'
            }}>
              As a digital transformation partner and change management architect, our expertise ensures smooth transitions. We dismantle market entry barriers in construction, protect against supply chain disruptions, and enhance collaboration and project management. Our predictive analytics optimize costs and resources, while advanced AI integration automates tasks, driving innovation in Infrastructure and Construction.
            </span>
          </div>
        </div>
        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row flex-1 justify-end'}`}>
          <FeatureCard heading="Digital Transformation Partner:" content={contents[0]} imgSrc={gifs[0]} isMobile={isMobile} />
          <FeatureCard heading="Change Management Expert:" content={contents[1]} imgSrc={gifs[1]} isMobile={isMobile} />
        </div>
      </div>

      <div className={`flex ${isMobile ? 'flex-col' : 'flex-wrap justify-start'}`}>
        <FeatureCard heading="Protection Against Supply Chain Issues:" content={contents[2]} imgSrc={gifs[2]} isMobile={isMobile} />
        <FeatureCard heading="Automate Routine Tasks:" content={contents[3]} imgSrc={gifs[3]} isMobile={isMobile} />
      </div>

      {!isMobile && (
        <img src={circlesvg} alt="Decorative Circles" className="absolute bottom-10 right-0" style={{ width: '343px', height: '343px' }} />
      )}
    </div>
  );
};

export default FeaturesGrid;

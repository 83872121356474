import React from 'react';
import bgimg from '../../assets/PNG/Productbannerbg.png';
import Screen from '../../assets/PNG/Screen.png'

const DocumentManagementSystem = () => {
  return (
    <div
      className="min-h-screen bg-cover bg-center text-white flex flex-col items-center font-roboto"
      style={{ backgroundImage: `url(${bgimg})` }}
    >
      <header className="w-full flex justify-between items-center p-5">
        <div className="space-x-4">
          {/* You can add elements here if needed */}
        </div>
      </header>

      <main className="flex-grow flex flex-col items-center justify-center ">
        <div className="text-center w-full">
          <h1 className="text-white" style={{ fontSize: '36px', fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>
            Document Management System
          </h1>
          <p className="text-white" style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: '400', lineHeight: '26px', wordWrap: 'break-word' }}>
            Elevate your project efficiency and collaboration with our specialized document <br/>management software tailored for the construction industry.
          </p>
        </div>

        <div
          style={{
           
            maxWidth: '250px', // You can adjust this to your desired button width
            padding: '10px 18px',
            backgroundColor: '#07ABDD',
            borderRadius: '45px',
            overflow: 'hidden',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '12px',
            display: 'inline-flex',
            cursor: 'pointer'
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#070557',
              fontSize: '16px',
              fontFamily: 'Roboto',
              fontWeight: '600',
              lineHeight: '20px',
              wordWrap: 'break-word',
            }}
          >
            Get 90 Day Free Trial
          </div>
        </div>

        <div className="flex space-x-4 mt-12 items-center justify-center">
          <img src={Screen} alt="Screenshot 1" className="w-1/4" />
         
        </div>
      </main>
    </div>
  );
};

export default DocumentManagementSystem;

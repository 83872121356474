import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import image from '../../../assets/PNG/handshake.png'; // Ensure you have the correct path to the image

const BusinessGrowSection = () => {
  return (
    <div className="p-6">
      <div className="relative w-full h-screen bg-gray-800 flex items-center justify-center bg-center rounded-md overflow-hidden">
        <img 
          src={image} 
          alt="Business Growth" 
          className="absolute inset-0 w-full h-full object-cover opacity-70 rounded-md" 
        />
        <div className="z-10 flex items-center justify-between w-full h-full px-8 mt-72">
          <div className="flex flex-col items-start text-left text-white w-2/3">
            <h1 className="text-3xl font-roboto font-semibold mb-4">Grow Your Business with Us</h1>
            <p className="text-lg mb-8">
              Contact us today to learn more about how we can support your specific needs <br/> and drive success in your sector.
            </p>
          </div>
          <div className='w-1/3 mr-12 px-8'>
            <Link to="/contact"> {/* Wrap the button in a Link component */}
              <button 
                className="bg-blue-500 text-[#070557] font-bold py-2 px-4 rounded-full cursor-pointer hover:bg-blue-700"
              >
                Get a Quote &rarr;
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessGrowSection;

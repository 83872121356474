import React from 'react';

const Why = () => {
  return (
    <div className="grid-col p-6" style={{ position: 'relative' }}>
      <div className="max-w-4xl p-6 bg-white">
        <h1 style={{ 
            fontSize: '38px', 
            fontFamily: 'Roboto', 
            fontWeight: 600, 
            lineHeight: '56px',
            background: 'linear-gradient(to right, #07ABDD, #68B24C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            wordWrap: 'break-word',
            color: '#07ABDD'
          }}>
          Our Document Management System for the Construction Industry Offers Unparalleled <br/> 
          Features and Benefits
        </h1>
      </div>
      <div className="p-6 flex bg-white">
        <div className='w-2/5'> </div>
        <div className='w-3/5'>
          <div style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 700, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginBottom: '0.5rem'
              }}>• Centralized Document Control:</p>
            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 400, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginLeft: '1rem',
                marginBottom: '1rem'
              }}>
              Our construction document management systems provide a single, secure location for all your project documents, making it easy to manage and access critical information.
            </p>

            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 700, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginBottom: '0.5rem'
              }}>• Enhanced Collaboration:</p>
            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 400, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginLeft: '1rem',
                marginBottom: '1rem'
              }}>
              Facilitate seamless collaboration among project teams with real-time document sharing and updates, ensuring everyone is on the same page.
            </p>

            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 700, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginBottom: '0.5rem'
              }}>• Improved Compliance and Security:</p>
            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 400, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginLeft: '1rem',
                marginBottom: '1rem'
              }}>
              Maintain compliance with industry regulations and safeguard your sensitive data with robust security features.
            </p>

            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 700, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginBottom: '0.5rem'
              }}>• Easy Integration:</p>
            <p style={{ 
                color: '#11222C', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: 400, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginLeft: '1rem'
              }}>
              Our document management system for construction industry integrates seamlessly with your existing tools and software, enhancing your current processes without disruption.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;

import React from 'react'


const careerbanner = () => {
  return (
    <div>
      <div className="relative flex flex-col min-h-screen items-center justify-center bg-black text-white py-56 w-full text-center" style={{ paddingTop: '20%' }}>
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
        >
           <source src={`${process.env.REACT_APP_BACKEND_URL}/api/v1/assets/?fileName=assets/videos/careers_landing_page_video.mp4`} type="video/mp4" />
        </video>

        <span className=" text-white z-10 text-4xl font-semibold" style={{ fontFamily: 'Roboto', wordWrap: 'break-word',}}>
          Come Innovate with Us! 
        </span>
        <br/>
        <span className="text-white z-10 text-xl font-normal leading-6 mt-2" style={{ fontFamily: 'Roboto', wordWrap: 'break-word' }}>
          Be part of a dynamic team driving innovation and digital <br/> transformation across industries.
        </span>
        <a
          href="#"
          className="no-underline bg-gradient-to-r from-blue-500 to-green-400 text-white px-6 py-3 mt-8 rounded-full shadow-lg transform transition-transform duration-200 hover:scale-105"
        >
          Check Out Career Opportunities
        </a>
      </div>
    </div>
  )
}

export default careerbanner

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Resources from './Resource';

const App = () => {
  const [blogsResources, setBlogsResources] = useState(null);
  const [updatesResources, setUpdatesResources] = useState(null);
  const [eventsResources, setEventsResources] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const containerRef = useRef(null);

  const CARD_WIDTH = 256; // Adjust this value if your card width changes
  const VISIBLE_CARDS = 5;
  const SCROLL_AMOUNT = CARD_WIDTH * VISIBLE_CARDS;

  const setContainerRef = (ref) => {
    containerRef.current = ref;
  };

  useEffect(() => {
    const updateButtonsState = () => {
      const container = containerRef.current;
      if (container) {
        const isAtStart = scrollLeft === 0;
        const isAtEnd = scrollLeft + container.clientWidth >= container.scrollWidth;
        setIsPrevDisabled(isAtStart);
        setIsNextDisabled(isAtEnd);
      }
    };

    updateButtonsState();
  }, [scrollLeft]);

  const handlePrevClick = () => {
    const newScrollLeft = Math.max(0, scrollLeft - SCROLL_AMOUNT);
    setScrollLeft(newScrollLeft);
    containerRef.current.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth',
    });
  };

  const handleNextClick = () => {
    const container = containerRef.current;
    if (container) {
      const newScrollLeft = Math.min(container.scrollWidth - container.clientWidth, scrollLeft + SCROLL_AMOUNT);
      setScrollLeft(newScrollLeft);
      container.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const getContent = async (type = '', latest = true) => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/content?page=0&type=${type}&latest=${latest}`,
        headers: {},
      };
      const contentData = await axios.request(config);
      switch (type) {
        case 'blog':
          setBlogsResources(contentData.data.data);
          break;
        case 'update':
          if (contentData.data.data.length === 0) {
            setUpdatesResources(null);
            break;
          }
          setUpdatesResources(contentData.data.data);
          break;
        case 'event':
          if (contentData.data.data.length === 0) {
            setEventsResources(null);
            break;
          }
          setEventsResources(contentData.data.data);
          break;
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getContent('blog');
    getContent('update');
    getContent('event');
  }, []);

  return (
    !isLoading && (
      <div className="p-4">
        {blogsResources && (
          <div className="relative">
            <h1 className="text-2xl font-bold mb-4 text-center">Check Out More Blogs</h1>
            <button
              onClick={handlePrevClick}
              className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow-md ${
                isPrevDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isPrevDisabled}
            >
              <FaChevronLeft />
            </button>
            <Resources resources={blogsResources} scrollLeft={scrollLeft} setContainerRef={setContainerRef} />
            <button
              onClick={handleNextClick}
              className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow-md ${
                isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isNextDisabled}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
        {updatesResources && (
          <div className="relative">
            <h1 className="text-2xl font-bold mb-4 text-center">Check Out News Updates</h1>
            <button
              onClick={handlePrevClick}
              className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow-md ${
                isPrevDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isPrevDisabled}
            >
              <FaChevronLeft />
            </button>
            <Resources resources={updatesResources} scrollLeft={scrollLeft} setContainerRef={setContainerRef} />
            <button
              onClick={handleNextClick}
              className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow-md ${
                isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isNextDisabled}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
        {eventsResources && (
          <div className="relative">
            <h1 className="text-2xl font-bold mb-4 text-center">Check Out Insights</h1>
            <button
              onClick={handlePrevClick}
              className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow-md ${
                isPrevDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isPrevDisabled}
            >
              <FaChevronLeft />
            </button>
            <Resources resources={eventsResources} scrollLeft={scrollLeft} setContainerRef={setContainerRef} />
            <button
              onClick={handleNextClick}
              className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow-md ${
                isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isNextDisabled}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    )
  );
};

export default App;

import React from 'react';

const Loader = () => {
  return (
    <button
      className="px-6 py-2 text-gray-800 rounded transition-colors"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disabled
    >
      <span
        className="inline-block w-6 h-6 border-4 border-solid border-white-500 border-t-transparent rounded-full animate-spin"
        style={{ borderTopColor: 'transparent' }}
      ></span>
    </button>
  );
};

export default Loader;

import React from 'react';
import svg1 from '../../assets/GIF/Profdevelopment.gif';
import svg2 from '../../assets/GIF/culture.gif';
import svg3 from '../../assets/GIF/Learning.gif';
import svg4 from '../../assets/GIF/Tools.gif';
import svg5 from '../../assets/GIF/Flexible.gif';
import svg6 from '../../assets/GIF/competitive.gif';

const perks = [
  {
    id: 1,
    icon: svg1,
    title: 'Professional Development',
    description: 'Access continuous learning opportunities and career advancement programs to help you grow.',
  },
  {
    id: 2,
    icon: svg2,
    title: 'Collaborative Culture',
    description: 'Be part of a supportive and inclusive team that values collaboration and open communication.',
  },
  {
    id: 3,
    icon: svg3,
    title: 'Continuous Learning Opportunities',
    description: 'Access a variety of training programs, workshops, and certifications to advance your career.',
  },
  {
    id: 4,
    icon: svg4,
    title: 'Advanced Technology Tools',
    description: 'Utilize state-of-the-art technology and resources to enhance your productivity and innovation.',
  },
  {
    id: 5,
    icon: svg5,
    title: 'Flexible Work Arrangements',
    description: 'Benefit from remote work options and flexible scheduling to balance your professional and personal life.',
  },
  {
    id: 6,
    icon: svg6,
    title: 'Competitive Compensation Package',
    description: 'Enjoy a competitive salary, performance bonuses, and comprehensive benefits.',
  },
];

const EmployeePerks = () => {
  return (
    <div className="text-white py-12" style={{ fontFamily: 'Roboto', background: '#11222C', color: 'white' }}>
      <div className="text-center mb-12">
        <h2 className="text-4xl font-semibold mb-4">Employee Perks</h2>
        <p className="text-lg font-normal">Experience a rewarding career with outstanding benefits and opportunities at PEMS Digital</p>
      </div>
      <div className="flex flex-wrap justify-center gap-16">
        {perks.map(perk => (
          <div key={perk.id} className="flex flex-col items-center w-full md:w-1/4 p-6 bg-[#2B3E48] rounded-lg " style={{  borderRadius: '10px', border: '1px rgba(255, 255, 255, 0.20) solid' }}>
            <div className="flex items-center w-full mb-4">
              <div style={{ width: '80px', height: '80px', padding: '15px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: '60px', height: '60px' }} src={perk.icon} alt={perk.title} />
              </div>
              <div className="w-2/3 text-start pl-4" style={{ color: 'white', fontSize: '24px', fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>
                {perk.title}
              </div>
            </div>
            <div style={{ width: '100%', color: 'white', fontSize: '15px', fontFamily: 'Roboto', fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word', textAlign: 'center' }}>
              {perk.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployeePerks;

import React from 'react'
import ContactForm from './ui/Forms/EnquiryForm/ContactForm'
import { NavBar } from './NavBar'
import Footer from './ui/Footer/FooterContact'

const ContactUs = () => {
  return (
    <div>
        <NavBar/>
        <ContactForm/>
        <Footer/>
    </div>
  )
}

export default ContactUs

import React from 'react';
import Video from '../../../assets/img/IndustriesVideo.mp4'

const Banner = () => {
  return (
    <div className="min-h-screen">
      <video 
        className="w-full h-full object-cover" 
        src={Video} 
        autoPlay 
        loop 
        muted 
      />
    </div>
  );
}

export default Banner;

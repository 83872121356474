import React from 'react';

const Believe = () => {
  return (
    <div className="grid-col p-6" style={{ position: 'relative' }}>
      <div className="max-w-4xl p-6 bg-white">
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
          <div style={{
            width: '20px', 
            height: '20px', 
            background: '#2B3E48', 
            borderRadius: '50%',
            marginRight: '10px', 
            marginBottom: '8px'
          }}></div>
          <h2 style={{ 
            color: '#2B3E48', 
            fontSize: '16px', 
            fontFamily: 'Roboto', 
            fontWeight: 600, 
            textTransform: 'uppercase', 
            wordWrap: 'break-word'
          }}>
            We Believe in Action
          </h2>
        </div>
        <h1 style={{ 
            fontSize: '24px', 
            fontFamily: 'Roboto', 
            fontWeight: 600, 
            lineHeight: '40px',
            wordWrap: 'break-word',
            background: 'linear-gradient(to right, #07ABDD, #68B24C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            wordWrap: 'break-word',
            color: '#07ABDD'
          }}>
          Specializing in transformative solutions, we empower organizations to<br/> embrace digital and business transformation. Our services enhance<br/> operational efficiency, streamline processes, and drive sustainable growth<br/> in Infrastructure & Construction Industry
        </h1>
      </div>
      <div className="p-6 flex bg-white">
        <div className='w-2/5'> </div>
        <div className='w-3/5'>
          <div style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            <p style={{ 
                color: '#11222C', 
                fontSize: '20px', 
                fontFamily: 'Roboto', 
                fontWeight: 700, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginBottom: '0.5rem'
              }}>• Innovative Solutions:</p>
            <p style={{ 
                color: '#11222C', 
                fontSize: '20px', 
                fontFamily: 'Roboto', 
                fontWeight: 400, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginLeft: '1rem',
                marginBottom: '1rem'
              }}>
              Customized approaches to foster innovation and drive business growth through digital initiatives.
            </p>

            <p style={{ 
                color: '#11222C', 
                fontSize: '20px', 
                fontFamily: 'Roboto', 
                fontWeight: 700, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginBottom: '0.5rem'
              }}>• Digital Transformation Strategies:</p>
            <p style={{ 
                color: '#11222C', 
                fontSize: '20px', 
                fontFamily: 'Roboto', 
                fontWeight: 400, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginLeft: '1rem',
                marginBottom: '1rem'
              }}>
              Tailored strategies to integrate cutting-edge technologies and optimize business processes.
            </p>

            <p style={{ 
                color: '#11222C', 
                fontSize: '20px', 
                fontFamily: 'Roboto', 
                fontWeight: 700, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginBottom: '0.5rem'
              }}>• Operational Efficiency:</p>
            <p style={{ 
                color: '#11222C', 
                fontSize: '20px', 
                fontFamily: 'Roboto', 
                fontWeight: 400, 
                lineHeight: '26px', 
                wordWrap: 'break-word',
                marginLeft: '1rem'
              }}>
              Solutions to streamline operations and maximize productivity through automation and advanced analytics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Believe;

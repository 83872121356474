import React from 'react';
import Product1 from '../../assets/img/product1.png';
import Product2 from '../../assets/img/Product2.png';

import P1 from '../../assets/svg/Product 1.svg';
import P2 from '../../assets/svg/Product 2.svg';

const Productpmt = ({ number, title, description, imageSrc, altText }) => {
  return (
    <div className="flex flex-col md:flex-row items-center p-6 mb-10">
      <div className="md:w-2/3 md:ml-6">
        <div className="flex items-center mb-4">
          <div className=" text-black rounded-full w-8 h-8 flex items-center justify-center text-lg font-bold" style={{ width:'100px', height:'100px'}} >  <img src={number}/></div>
          <h2 className="text-2xl font-bold ml-4">{title}</h2>
        </div>
        <p className="text-lg px-6">{description}</p>
        <button className="mt-4 px-4 py-2 bg-blue-500 text-black rounded-full hover:bg-blue-400">Know More</button>
      </div>
      <div className="md:w-1/3 mb-6 py-4 md:mb-0">
        <img src={imageSrc} alt={altText} className="rounded-lg" />
      </div>
    </div>
  );
};

const Productbot = ({ number, title, description, imageSrc, altText }) => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center p-6 mb-10">
      <div className="md:w-1/3 py-4 mb-6 md:mb-0">
        <img src={imageSrc} alt={altText} className="rounded-lg"/>
      </div>
      <div className="md:w-2/3 md:ml-6">
        <div className="flex items-center mb-4">
          <div className="text-black rounded-full w-8 h-8 flex items-center justify-center text-lg font-bold" style={{ width:'100px', height:'100px'}}>
            <img src={number} alt="" />
          </div>
          <h2 className="text-2xl font-bold ml-4">{title}</h2>
        </div>
        <p className="text-lg px-6">{description}</p>
        <button className="mt-4 px-4 py-2 bg-blue-500 text-black rounded-full hover:bg-blue-400">Know More</button>
      </div>
    </div>
  );
};


function App() {
  return (
    <div style={{fontFamily: 'Roboto'}}>
    <div className="min-h-screen p-8" style={{ background: '#11222C', color: 'white' }}>
      <div className="text-center mb-16">
        <h1 className="" style={{ fontSize: "52px", fontFamily: "Roboto, sans-serif", fontWeight: 600 }}>Our Products</h1>
        <p className="mt-4 text-lg">
          Transforming construction industries with cutting-edge solutions. Empowering your business with innovative tools for seamless operations and enhanced customer experiences.
        </p>
      </div>
      <div className="space-y-16">
        <Productpmt
          number={P1}
          title="Document Management System"
          description="Our advanced Document Management System is designed specifically for the construction industry, streamlining project workflows, enhancing collaboration, and ensuring on-time delivery with unparalleled efficiency."
          imageSrc={Product1} 
          altText="Project Management Tool screenshot"
        />
        <Productbot
          number={P2}
          title="AI ChatBOT"
          description="Our state-of-the-art AI Chatbot revolutionizes customer interactions, providing instant, accurate responses and support 24/7, enhancing user experience and driving customer satisfaction to new heights."
          imageSrc={Product2} 
          altText="AI Chatbot screenshot"
        />
      </div>
    </div>
    </div>
  );
}

export default App;

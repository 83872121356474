import React from 'react';
import img1 from '../../assets/PNG/Insights.png'
import img2 from '../../assets/PNG/Experience.png'
import img3 from '../../assets/PNG/Digital.png'

const Section = ({ title, points1, points2, imgSrc, altText, reverse }) => (
  <div className={`flex flex-col md:flex-row items-start p-4 bg-[#11222C] text-white w-full md:max-w-6xl ${reverse ? 'flex-row-reverse' : ''}`}>
    <div className="flex-1 p-4">
      <div className="text-left text-3xl font-semibold mb-8">{title}</div>
      <div className="flex flex-col md:flex-row w-full mb-8">
        <div className="mr-4">
          <ul className="text-lg font-normal leading-10 list-disc pl-5">
            {points1.map((point, index) => (
              <li key={index} style={{ 
                color: 'white', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: '400', 
                lineHeight: '43px', 
                wordWrap: 'break-word' 
              }}>{point}</li>
            ))}
          </ul>
        </div>
        <div>
          <ul className="text-lg font-normal leading-10 list-disc pl-5">
            {points2.map((point, index) => (
              <li key={index} style={{ 
                color: 'white', 
                fontSize: '16px', 
                fontFamily: 'Roboto', 
                fontWeight: '400', 
                lineHeight: '43px', 
                wordWrap: 'break-word' 
              }}>{point}</li>
            ))}
          </ul>
        </div>
      </div>
      <a href="#" className="text-blue-400 underline">CONNECT WITH US →</a>
    </div>
    <div className="flex justify-center w-full md:w-1/2 mb-8">
      <img src={imgSrc} alt={altText} className="w-full max-w-md" />
    </div>
  </div>
);

const Explore3Ds = () => (
  <div className="p-12 bg-[#11222C] text-white">
    <div className="text-center text-5xl font-bold mb-8">
      Explore the 3Ds
    </div>
    <div className="max-w-6xl mx-auto flex flex-col items-start justify-start">
      <Section
        title="Data Management and Insights"
        points1={[
          'Data Collections and management',
          'Data analysis and modeling',
          'Predictive maintenance',
          'Asset management',
        ]}
        points2={[
          'Real-time monitoring',
          'Visualisation and mapping',
          'Decision support',
          'Data monetisation',
        ]}
        imgSrc={img1}
        altText="Data Management and Insights"
      />
      <Section
        title="Digital Transformation"
        points1={[
          'Data governance and management',
          'System integration',
          'Cybersecurity',
          'Continuous improvement and optimization',
        ]}
        points2={[
          'Strategy development',
          'Digital maturity assessment',
          'Business process optimization',
          'Technology implementation',
        ]}
        imgSrc={img3}
        altText="Digital Transformation"
        reverse={true}
      />
      <Section
        title="Digital Experience Services"
        points1={[
          'User experience (UX) design',
          'User research and testing',
          'Content strategy and development',
        ]}
        points2={[
          'Multichannel and cross-device optimization',
          'Chatbot and visual assistant',
        ]}
        imgSrc={img2}
        altText="Digital Experience Services"
      />
    </div>
  </div>
);

export default Explore3Ds;

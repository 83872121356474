import React from 'react';

const InfoSection = () => {
  return (
    <div className='p-12'>
      <div className="bg-white py-12 px-6 flex justify-start">
        <div className="w-2/3 space-y-6">
          <p className="text-lg text-[#11222C] text-[16px] font-roboto font-normal leading-[26px] break-words">
            <span className="font-bold">• Founded with a vision to transform the digital landscape, </span>
            PEMS Digital has grown into a trusted partner for businesses seeking to navigate the complexities of the modern market.
          </p>
          <p className="text-lg text-[#11222C] text-[16px] font-roboto font-normal leading-[26px] break-words">
            <span className="font-bold">• Our team of seasoned professionals brings together a wealth of experience and a passion for innovation, </span>
            ensuring that we deliver solutions that are not only effective but also forward-thinking.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;

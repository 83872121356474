import React from 'react';

const ThankYouCard = ({ color, message, heading }) => {
  const cardStyle = {
    border: `2px solid ${color}`,
    borderRadius: '10px',
    padding: '20px',
    maxWidth: '400px',
    margin: '0 auto',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: color,
    marginBottom: '10px',
  };

  const messageStyle = {
    fontSize: '16px',
    color: '#333',
    marginBottom: '20px',
  };

  return (
    <div style={cardStyle}>
      <h1 style={headingStyle}>{heading}</h1>
      <p style={messageStyle}>{message}</p>
    </div>
  );
};

export default ThankYouCard;

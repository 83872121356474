import React from 'react';
import imgquote from '../../assets/PNG/Quotes.png';

const Quote = () => {
  return (
    <div style={{ textAlign: 'center', minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src={imgquote} alt="Quote" />
    </div>
  );
};

export default Quote;

import React from 'react';
import imgquote from '../../assets/PNG/Quotes.png'

const Apply = () => {
  return (
    <>
      <div className="flex items-center justify-center h-[524px] bg-gray-800" style={{ fontFamily: 'Roboto', background: '#11222C', color: 'white' }}>
        <div className="text-center text-white">
          <div style={{ width: '100%', textAlign: 'center' }}>
            <span style={{ color: 'white', fontSize: '36px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word', marginBottom: '16px', display: 'block' }}>
              How to Apply<br />
            </span>
            <span style={{ color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
              Couldn’t find your preferred job role? Send your resume and cover letter to <br />
            </span>
            <span style={{ color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
              {' '}
            </span>
            <span style={{ color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700, textDecoration: 'underline', lineHeight: '26px', wordWrap: 'break-word' }}>
              careers@pemsdigital.com
            </span>
            <span style={{ color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
              {' '}and we’ll get back to you. Tell us why you’re passionate about digital <br/> transformation and how you can contribute to our team.
            </span>
          </div>
          <div style={{ marginTop: '24px', color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
            Join PEMS Digital and be part of a team that’s shaping the future of digital <br/>transformation. Together, we can achieve great things!
          </div>
        </div>
        
      </div>
      <div className="mx-auto px-16" style={{ fontFamily: 'Roboto', background: '#11222C' }}>
    <div className="flex flex-wrap justify-between items-center border-t border-gray-600 pt-4 text-sm" style={{ paddingLeft: '10px', paddingRight: '10px' }}></div>
</div>

    </>
  );
};

export default Apply;

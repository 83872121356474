import React from 'react';
import { Box, Heading, Stack, Text, Button } from '@chakra-ui/react';

const EmptyState = ({ heading, message }) => {
  return (
    <Box
      backgroundColor="white"
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      border="1px solid"
      borderColor="black"
    >
      <Box
        backgroundColor="gray.50"
        borderTopLeftRadius={8}
        borderTopRightRadius={8}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        height="40px"
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        p={16}
        borderRadius={8}
      >
        <Heading size="lg" color="black">
          No Job Opportunities Found for your job hunt filters 😔
        </Heading>
        <Text color="black">
          But stay connected , we keep on updating our databases🤘
        </Text>
      </Stack>
    </Box>
  );
};

export default EmptyState;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import Loader from '../../Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import FooterCareer from '../../Footer/FooterCareer'


const countryCodes = [
  { code: 1, label: 'US' },
  { code: 44, label: 'UK' },
  { code: 91, label: 'IN' },
];

const CandidateForm = ({ roleName }) => {
  const { category } = useParams();
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberCode, setPhoneNumberCode] = useState(countryCodes[2].code);
  const [highestQualification, setHighestQualification] = useState('');
  const [institution, setInstitution] = useState('');
  const [lastCompany, setLastCompany] = useState('');
  const [experience, setExperience] = useState('');
  const [role, setRole] = useState(category);
  const [resume, setResume] = useState(null);
  const resumeRef = useRef(null);
  const [servingNotice, setServingNotice] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setErrors({ ...errors, [name]: undefined });
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phoneNumberCode':
        setPhoneNumberCode(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'highestQualification':
        setHighestQualification(value);
        break;
      case 'institution':
        setInstitution(value);
        break;
      case 'lastCompany':
        setLastCompany(value);
        break;
      case 'experience':
        setExperience(value);
        break;
      case 'servingNotice':
        setServingNotice(value);
        break;
      case 'noticePeriod':
        setNoticePeriod(value);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    console.log(file);
    if (file && file.size > 10 * 1024 * 1024) {
      setErrors({ resume: 'File size exceeds 10MB limit' });
    } else {
      setResume(file);
      setErrors({ resume: '' });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const validationErrors = {};
    console.log(phoneNumber);

    if (!firstName) {
      validationErrors.firstName = 'First Name is required';
    }
    if (!lastName) {
      validationErrors.lastName = 'Last Name is required';
    }
    if (!email) {
      validationErrors.email = 'Email is required';
    }
    if (!phoneNumber) {
      validationErrors.phoneNumber = 'Phone Number is required';
    }
    if (!highestQualification) {
      validationErrors.highestQualification =
        'Highest Qualification is required';
    }
    if (!institution) {
      validationErrors.institution = 'Institution is required';
    }
    if (!experience) {
      validationErrors.experience = 'Experience is required';
    }
    if (!resume) {
      validationErrors.resume = 'Resume is required';
    }
    if (!servingNotice) {
      validationErrors.servingNotice =
        'Notice Period serving status is required';
    }
    if (!noticePeriod) {
      validationErrors.noticePeriod = 'Notice Period is required';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      formSubmitApiCall();
      console.log('Form submitted successfully!');
    }
  };

  const formSubmitApiCall = async (event) => {
    setLoading(true);
    const formData = new FormData();
    const servingNoticeBoolean = servingNotice === 'Yes' ? true : false;
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('countryCode', phoneNumberCode);
    formData.append('lastEducationDegree', highestQualification);
    formData.append('lastInstitution', institution);
    formData.append('experience', experience);
    formData.append('role', role);
    formData.append('noticePeriod', noticePeriod);
    formData.append('servingNotice', servingNoticeBoolean);
    formData.append('resume', resume, resume.name);
    const boundary = Math.random().toString().slice(2);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/carrers/jobApplication`,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${boundary}`,
      },
      data: formData,
    };

    try {
      const response = await axios.request(config);
      clearForm();
      console.log(JSON.stringify(response.data));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setPhoneNumberCode(countryCodes[2].code);
    setHighestQualification('');
    setInstitution('');
    setLastCompany('');
    setExperience('');
    setRole(category);
    setResume('');
    setServingNotice('');
    setNoticePeriod('');
    setLoading(false);
    setErrors({});
  };

  return (
    <>
   <div className='mb-16'> </div>
    <form
      onSubmit={handleSubmit}
      className="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-md text-black font-family-roboto"
    >
      <h2 className="text-4xl font-semibold text-gray-700 mb-6 text-center">
        Application Form - {role}
      </h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.firstName && (
            <div className="mt-1 text-red-500 text-xs">{errors.firstName}</div>
          )}
        </div>

        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              name="lastName"
              placeholder="Last name"
              value={lastName}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.lastName && (
            <div className="mt-1 text-red-500 text-xs">{errors.lastName}</div>
          )}
        </div>
      </div>
      <div className="mt-4" />
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.email && (
            <div className="mt-1 text-red-500 text-xs">{errors.email}</div>
          )}
        </div>

        <div className="flex flex-col">
          <div className="flex items-center">
            <select
              name="phoneNumberCode"
              value={phoneNumberCode}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full col-span-2 mr-2"
              style={{ width: '92px', height: '44px' }}
            >
              {countryCodes.map((code) => (
                <option key={code.code} value={code.code}>
                  (+{code.code})
                </option>
              ))}
            </select>
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '295px', height: '44px' }}
            />

            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.phoneNumber && (
            <div className="mt-1 text-red-500 text-xs">
              {errors.phoneNumber}
            </div>
          )}
        </div>
      </div>
      <div className="mt-4" />
      <div className="grid grid-cols-2 gap-4">
        {/* Highest Qualification */}
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              name="highestQualification"
              placeholder="Highest Qualification"
              value={highestQualification}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.highestQualification && (
            <div className="mt-1 text-red-500 text-xs">
              {errors.highestQualification}
            </div>
          )}
        </div>

        {/* Institution */}
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              name="institution"
              placeholder="Institution"
              value={institution}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.institution && (
            <div className="mt-1 text-red-500 text-xs">
              {errors.institution}
            </div>
          )}
        </div>
      </div>
      <div className="mt-4" />
      <div className="grid grid-cols-2 gap-4">
        {/* Last Company */}
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              name="lastCompany"
              placeholder="Last Company"
              value={lastCompany}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            />
          </div>
        </div>

        {/* Experience */}
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              name="experience"
              placeholder="Experience"
              value={experience}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full ml-3.5"
              style={{ width: '395px', height: '44px' }}
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.experience && (
            <div className="mt-1 text-red-500 text-xs">{errors.experience}</div>
          )}
        </div>
      </div>
      <div className="mt-4" />
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              name="role"
              placeholder="Role"
              value={role}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
              disabled
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="flex items-center">
              <button
                type="button"
                onClick={() => resumeRef.current.click()}
                className="border border-black rounded-md px-3 py-2 text-gray-400 hover:bg-gray-100 flex justify-start"
                style={{ width: '395px', height: '44px' }}
              >
                {resume ? 'Resume added' : 'Upload Resume'}
              </button>
              <span className="text-red-500 ml-2">*</span>
            </div>
            <input
              type="file"
              id="resume"
              name="resume"
              ref={resumeRef}
              onChange={handleFileChange}
              className="hidden"
            />
            {errors.resume && (
              <div className="mt-1 text-red-500 text-xs">{errors.resume}</div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4" />
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <select
              name="servingNotice"
              id="servingNotice"
              value={servingNotice} // State variable for selected value
              onChange={handleInputChange}
              className="border border-gray-300 text-gray-500 rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            >
              <option value="">Serving Notice Period</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.servingNotice && (
            <div className="mt-1 text-red-500 text-xs">
              {errors.servingNotice}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="number"
              name="noticePeriod"
              placeholder="Notice Period (months)"
              value={noticePeriod}
              onChange={handleInputChange}
              className="border border-black rounded-md px-3 py-2 w-full"
              style={{ width: '395px', height: '44px' }}
            />
            <span className="text-red-500 ml-2">*</span>
          </div>
          {errors.lastName && (
            <div className="mt-1 text-red-500 text-xs">{errors.lastName}</div>
          )}
        </div>
      </div>
      <div className="mt-6">
        <div>
          {!loading ? (
            <button
              type="submit"
              className="px-6 py-2 bg-black text-white hover:bg-gray-300 transition-colors flex items-center"
              disabled={loading}
            >
              Submit
            </button>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </form>
    <FooterCareer/>
    </>
  );
};

export default CandidateForm;

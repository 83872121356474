
import React from 'react';
import business from '../../assets/PNG/Transformbusiness.png'

function ServicesPage() {
  return (
    <div className="bg-white min-h-screen flex items-center justify-center p-6">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2">
        <div className='flex'>
        <div style={{
            width: '20px', 
            height: '20px', 
            background: '#2B3E48', 
            borderRadius: '50%',
            marginRight: '10px', 
            marginBottom: '8px'
          }}></div>
          <div style={{ color: '#2B3E48', fontSize: '16px', fontFamily: 'Roboto', fontWeight: '600', textTransform: 'uppercase', wordWrap: 'break-word' }}>
            Transform Your Business
          </div>
          </div>
          <h1 style={{ 
            fontSize: '40px', 
            fontFamily: 'Roboto', 
            fontWeight: 600, 
            lineHeight: '40px',
            wordWrap: 'break-word',
            background: 'linear-gradient(to right, #07ABDD, #68B24C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            wordWrap: 'break-word',
            color: '#07ABDD'
          }}>
            Comprehensive Digital and Business Transformation Services
          </h1>
          <p style={{ color: '#11222C', fontSize: '16px', fontFamily: 'Roboto', fontWeight: '400', lineHeight: '26px', wordWrap: 'break-word' }}>
            Unlock the full potential of your organization with our comprehensive digital and business transformation services. Our expertise helps streamline processes, enhance operational efficiency, and drive innovation, ensuring your business stays competitive and thrives in the modern landscape.
          </p>
        </div>
        <div className="md:w-1/2 md:pl-6 mt-6 md:mt-0 flex justify-center items-center space-x-6">
          <div className="relative w-100 h-100 ">
            <img
              src={business}
              alt="Digital transformation"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesPage;

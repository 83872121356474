import React from 'react';
import Infrastructure from '../../../assets/PNG/Infrastructure.png'
import Automobiles from '../../../assets/PNG/Automobiles.png'
import Real from '../../../assets/PNG/Real.png'
import Energy from '../../../assets/PNG/Power.png'
import Sports from '../../../assets/PNG/Sports.png'
import Travel from '../../../assets/PNG/Travel.png'
import Transportation from '../../../assets/PNG/Transportation.png'
import Environment from '../../../assets/PNG/Environment.png'
import Railways from '../../../assets/PNG/Railways.png'
import Retail from '../../../assets/PNG/Retail.png'
import Media from '../../../assets/PNG/Media.png'

const Card = ({ title, description, featuresLeft, featuresRight, imgSrc, reverse, colorScheme }) => {
  return (
    <div className={`flex flex-col md:flex-row items-center md:items-start ${colorScheme.bg} ${colorScheme.text} mt-16 px-12  py-8 ${reverse ? 'md:flex-row-reverse' : ''}`}>
      <div className="w-full md:w-1/2 mb-8 md:mb-0">
        <h1 className="text-3xl font-bold flex items-center mb-4">
          <span className="mr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2l4-4M7 7v.01M7 12v.01M7 17v.01M3 3h18v18H3V3z"
              />
            </svg>
          </span>
          {title}
        </h1>
        <p className="text-lg mb-6">{description}</p>
        <div className="flex justify-center space-x-8">
          <div className={`border-r-2 ${colorScheme.line}`}></div>
          <div className="flex flex-col space-y-4">
            {featuresLeft.map((feature, index) => (
              <p key={index} className={`${colorScheme.feature} cursor-pointer`}>
                {feature}
              </p>
            ))}
          </div>
          <div className={`border-r-2 ${colorScheme.line}`}></div>
          <div className="flex flex-col space-y-4">
            {featuresRight.map((feature, index) => (
              <p key={index} className={`${colorScheme.feature} cursor-pointer`}>
                {feature}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 py-4 px-8">
    <img src={imgSrc} alt={title} className="w-full h-auto max-w-[500px] max-h-[300px] rounded-lg shadow-lg" />
</div>

    </div>
  );
};

const RealEstateSection = () => {
  const cards = [
    {
      title: 'Infrastructure',
      description: 'We provide specialized digital solutions designed to meet the unique needs of the construction and infrastructure sector.',
      featuresLeft: [
        'Project Data Management',
        'Product Management Insights & Dashboard',
        'BIM Integration',
        'Stock Management System',
        'Procurement Streamlining'
      ],
      featuresRight: [
        'Real-Time Analytics and IOT Integration',
        'Workflow & Process Mapping',
        'Staff Tracking & Health Monitoring',
        'Remote Video Inspection'
      ],
      imgSrc: Infrastructure,
      colorScheme: {
        bg: 'bg-white',
        text: 'text-black',
        line: 'border-black',
        feature: 'text-blue-600 hover:text-blue-800'
      }
    },
    {
      title: 'Automobiles',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed.',
      featuresLeft: [
        'Vehicle Infotainment and Connected System',
        'Connected Car Tech',
        'Dash Camera Integration and Statics'
      ],
      featuresRight: [
        'Vehicle Inventory Management System',
        'Real Time Driver Safety Analysis',
        'Driving Style Analysis'
      ],
      imgSrc: Automobiles,
      colorScheme: {
        bg: 'bg-[#11222C]',
        text: 'text-white',
        line: 'border-white',
        feature: 'text-blue-400 hover:text-blue-600'
      }
    },
    {
      title: 'Real Estate',
      description: 'Revolutionizing real estate with innovative digital solutions for enhanced efficiency and growth.',
      featuresLeft: [
        'Building Management System',
        'Connected Building System',
        'Remote Property Management',
        'Data Asset Management'
      ],
      featuresRight: [
        'Procurement Support Softwares',
        'Inventory Management Softwares',
        'Real-Time Failure Reporting',
        'Virtual Reality Applications'
      ],
      imgSrc: Real,
      colorScheme: {
        bg: 'bg-white',
        text: 'text-black',
        line: 'border-black',
        feature: 'text-blue-600 hover:text-blue-800'
      }
    },
    {
      title: 'Logistics and Supply Chain',
      description: 'Optimizing logistics and supply chain operations with cutting-edge digital solutions for seamless efficiency and reliability.',
      featuresLeft: [
        'Fleet Management System',
        'Travel & Fleet Analysis',
        'Warehouse Management Solutions'
      ],
      featuresRight: [
        'End-to-End Tracking Analysis',
        'Procurement Order Management',
        'Process Delivery Optimisation'
      ],
      imgSrc: Transportation,
      colorScheme: {
        bg: 'bg-gray-900',
        text: 'text-white',
        line: 'border-white',
        feature: 'text-blue-400 hover:text-blue-600'
      }
    },
    {
      title: 'Energy and Power',
      description: 'Empowering the energy and power sector with innovative digital solutions for enhanced efficiency and sustainability.',
      featuresLeft: [
        'Real-Time Power Monitoring & Analysis',
        'Smart Meter Reading Monitoring',
        'Operational Efficiency Analysis & Monitoring'
      ],
      featuresRight: [
        'Asset Condition Management',
        'Advanced Energy Billing Solution',
        'End-to-End Monitoring & Predictive Analysis'
      ],
      imgSrc: Energy,
      colorScheme: {
        bg: 'bg-white',
        text: 'text-black',
        line: 'border-black',
        feature: 'text-blue-600 hover:text-blue-800'
      }
    },
    {
      title: 'Sports and Adventure',
      description: 'Technology for sports enthusiasts, adventurers, and outdoor activities.',
      featuresLeft: [
        'Smart Ticketing Solutions',
        'Tourist Tracking Solutions',
        'Camping Security Data Analysis'
      ],
      featuresRight: [
        'Commercial Maximization',
        'Event Data Visualization',
        'Integrated AI Solutions and Visualization'
      ],
      imgSrc: Sports,
      colorScheme: {
        bg: 'bg-gray-900',
        text: 'text-white',
        line: 'border-white',
        feature: 'text-blue-400 hover:text-blue-600'
      }
    },
    {
      title: 'Travel, Tourism and Hospitality',
      description: 'Smart solutions for travel, tourism, and hospitality enhancing user experience.',
      featuresLeft: [
        'Customer Relationship Management',
        'Tourist Management System',
        'Pilgrim Management System'
      ],
      featuresRight: [
        'Tourist Chatbot Integration',
        'Loyalty and Feedback Solution',
        'Hospitality Management Software'
      ],
      imgSrc: Travel,
      colorScheme: {
        bg: 'bg-white',
        text: 'text-black',
        line: 'border-black',
        feature: 'text-blue-600 hover:text-blue-800'
      }
    },
    {
      title: 'Transportation and Mobility',
      description: 'Transforming urban and rural transportation through innovative technology and smart systems.',
      featuresLeft: [
        'Crash Data Analysis',
        'Database Management',
        'Efficiency Enhancement System',
        'Vehicle Tracking System'
      ],
      featuresRight: [
        'Toll Management System',
        'Fleet and Terminal Management Solution',
        'Passenger Management System',
        'Mobility Analysis Software'
      ],
      imgSrc: Transportation,
      colorScheme: {
        bg: 'bg-gray-900',
        text: 'text-white',
        line: 'border-white',
        feature: 'text-blue-400 hover:text-blue-600'
      }
    },
    {
      title: 'Environment and Sustainability',
      description: 'Leveraging technology for a cleaner, sustainable, and efficient environment.',
      featuresLeft: [
        'Customer Relationship Management',
        'Tourist Management System',
        'Pilgrim Management System'
      ],
      featuresRight: [
        'Tourist Chatbot Integration',
        'Loyalty and Feedback Solution',
        'Hospitality Management Software'
      ],
      imgSrc: Environment,
      colorScheme: {
        bg: 'bg-white',
        text: 'text-black',
        line: 'border-black',
        feature: 'text-blue-600 hover:text-blue-800'
      }
    },
    {
      title: 'Railways and Urban Transportation',
      description: 'Enhancing railways and urban transportation with state-of-the-art digital solutions.',
      featuresLeft: [
        'Video and Image Analytics',
        'Intelligent Automation',
        'Passenger Management System and Statistics',
        'Asset Management System'
      ],
      featuresRight: [
        'Condition Based Predictive Maintenance',
        'Inspection Management System',
        'Fleet Management and Monitoring',
        'Asset Optimisation'
      ],
      imgSrc: Railways,
      colorScheme: {
        bg: 'bg-gray-900',
        text: 'text-white',
        line: 'border-white',
        feature: 'text-blue-400 hover:text-blue-600'
      }
    },
    {
      title: 'Retail and Consumer Market',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed.',
      featuresLeft: [
        'Loyalty Program Solution',
        'Customer Management Solution',
        'Instore Experience Solutions',
        'Inventory Management Solution'
      ],
      featuresRight: [
        'Supply Chain Integrated Solution',
        'Product Market Analysis and Statistics',
        'Warehousing and Master Data Management and Analytics'
      ],
      imgSrc: Retail,
      colorScheme: {
        bg: 'bg-white',
        text: 'text-black',
        line: 'border-black',
        feature: 'text-blue-600 hover:text-blue-800'
      }
    },
    {
      title: 'Media and Entertainment',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed.',
      featuresLeft: [
        'Digital Experience Solutions',
        'User Research and Analysis',
        'Content Strategy and Development',
        'Media Supply Chain Management',
        'Marketing and Business Analytics'
      ],
      featuresRight: [
        'Digital Road Map',
        'Application Modernisation',
        'News Data Analytics',
        'News Data Bank',
        'Big Data and Database Management Solution'
      ],
      imgSrc: Media,
      colorScheme: {
        bg: 'bg-gray-900',
        text: 'text-white',
        line: 'border-white',
        feature: 'text-blue-400 hover:text-blue-600'
      }
    }
  ];

  return (
    <div>
    {cards.map((card, index) => (
      <Card
        key={index}
        title={card.title}
        description={card.description}
        featuresLeft={card.featuresLeft}
        featuresRight={card.featuresRight}
        imgSrc={card.imgSrc}
        reverse={index % 2 !== 0}
        colorScheme={index % 2 === 0 ? {
          bg: 'bg-white',
          text: 'text-black',
          line: 'border: 1px rgba(10.61, 10.61, 10.61, 0.40) solid',
          feature: 'text-blue-600 hover:text-blue-800'
        } : {
          bg: 'bg-[#11222C]',
          text: 'text-white',
          line: 'border: 1px rgba(10.61, 10.61, 10.61, 0.40) solid',
          feature: 'text-blue-400 hover:text-blue-600'
        }}
      />
    ))}
  </div>
  );
};

export default RealEstateSection;

import React from "react";

const Dashboard = () => {
  return (
    <>
      <div className="px-12 py-8">
        <div className="w-full h-full bg-white rounded-2xl border border-gray-400 relative">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Infrastructure */}
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-4">Infrastructure:</h2>
              <p
                className="mb-4"
                style={{
                  color: "#11222C",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "26px",
                  wordWrap: "break-word",
                }}
              >
                We provide specialized digital solutions designed to meet the
                unique needs of the construction and infrastructure sector.
              </p>
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {[
                  "Project Data Management",
                  "Real-Time Analytics",
                  "Product Management Insights & Dashboard",
                  "Workflow & Process Mapping",
                  "Stock Management System",
                  "Staff Tracking & Health Monitoring",
                  "Procurement Streamlining",
                  "Remote Video Inspection",
                ].map((item) => (
                  <li
                    key={item}
                    style={{
                      color: "#07ABDD",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      wordWrap: "break-word",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            {/* Real Estate */}
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-4">Real Estate:</h2>
              <p
                className="mb-4"
                style={{
                  color: "#11222C",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "26px",
                  wordWrap: "break-word",
                }}
              >
                Revolutionizing real estate with innovative digital solutions
                for enhanced efficiency and growth.
              </p>
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {[
                  "Building Management System",
                  "Procurement Support Softwares",
                  "Connected Building System",
                  "Inventory Management Softwares",
                  "Remote Property Management",
                  "Real-Time Failure Reporting",
                  "Data Asset Management",
                  "Virtual Reality Applications",
                ].map((item) => (
                  <li
                    key={item}
                    style={{
                      color: "#07ABDD",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      wordWrap: "break-word",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            {/* Logistics and Supply Chain */}
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-4">
                Logistics and Supply Chain:
              </h2>
              <p
                className="mb-4"
                style={{
                  color: "#11222C",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "26px",
                  wordWrap: "break-word",
                }}
              >
                Optimizing logistics and supply chain operations with
                cutting-edge digital solutions for seamless efficiency and
                reliability.
              </p>
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {[
                  "Fleet Management System",
                  "End-to-End Tracking Analysis",
                  "Travel & Fleet Analysis",
                  "Procurement Order Management",
                  "Warehouse Management Solutions",
                  "Process Delivery Optimisation",
                ].map((item) => (
                  <li
                    key={item}
                    style={{
                      color: "#07ABDD",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      wordWrap: "break-word",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            {/* Energy and Power */}
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-4">Energy and Power:</h2>
              <p
                className="mb-4"
                style={{
                  color: "#11222C",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  lineHeight: "26px",
                  wordWrap: "break-word",
                }}
              >
                Empowering the energy and power sector with innovative digital
                solutions for enhanced efficiency and sustainability.
              </p>
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {[
                  "Real-Time Power Monitoring & Analysis",
                  "Asset Condition Management",
                  "Smart Meter Reading Monitoring",
                  "Advanced Energy Billing Solution",
                  "Operational Efficiency Analysis & Monitoring",
                  "End-to-End Monitoring & Predictive Analysis",
                ].map((item) => (
                  <li
                    key={item}
                    style={{
                      color: "#07ABDD",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      wordWrap: "break-word",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* Vertical Line */}
          <div className="absolute left-1/2 top-20 transform -translate-x-[45%] w-px h-1/3 border border-gray-400"></div>
          <div className="absolute left-1/2 bottom-20 transform -translate-x-[45%] w-px h-1/3 border border-gray-400"></div>
          {/* Horizontal Line */}
          <div className="absolute top-1/2 left-20 transform -translate-y-[45%] w-1/3 h-px border border-gray-400"></div>
          <div className="absolute top-1/2 right-20 transform -translate-y-[45%] w-1/3 h-px border border-gray-400"></div>
          {/* View All Button */}
        </div>
      </div>

      <div className="flex justify-center my-2">
        <a
          href="/Industries/View-all-Industries"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "40px",
            paddingRight: "40px",
            background: "#2B3E48",
            borderRadius: "45px",
            alignItems: "center",
            bottom: "20px",
            color: "white",
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: 600,
            lineHeight: "20px",
            wordWrap: "break-word",
            textDecoration: "none",
            display: "inline-block",
          }}
        >
          View All
        </a>
      </div>
    </>
  );
};

export default Dashboard;

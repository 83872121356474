import React from 'react';
import bgimg from '../../assets/PNG/ServicesBanner.png'

const Banner = () => {
  return (
    <div>
      <div className="relative flex flex-col min-h-screen items-center justify-center bg-black text-white py-56 w-full text-center" style={{ paddingTop: '20%' }}>
        <img
          src={bgimg} // Replace this URL with the actual image URL you want to use
          alt="Background"
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
        />

        <span className="z-10" style={{ color: 'white', fontSize: '36px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word' }}>
          Empowering Business Transformation Solutions
        </span>
        <br/>
        <span className="z-10 mt-2" style={{ color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
          We offer a comprehensive suite of services tailored to optimize every aspect of construction project management.<br/> From enhancing efficiency to ensuring seamless collaboration, our solutions are designed to empower your team and drive project success.
        </span>
        <a
          href="/Contact"
          className="no-underline bg-gradient-to-r from-blue-500 to-green-400 text-white px-6 py-3 mt-8 rounded-full shadow-lg transform transition-transform duration-200 hover:scale-105"
          style={{ color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 600, lineHeight: '20px', wordWrap: 'break-word' }}
        >
          Ready for Transformation? Let’s Talk
        </a>
      </div>
    </div>
  );
};

export default Banner;
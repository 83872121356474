import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const insertGTMScripts = () => {
  const head = document.head;
  const body = document.body;

  // Script for Head
  const headScript = document.createElement('script');
  headScript.async = true;
  headScript.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GTM_ID}`;
  head.appendChild(headScript);

  // Hotjar script for Head
  const hotjarScript = document.createElement('script');
  hotjarScript.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${process.env.REACT_APP_HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;
  head.appendChild(hotjarScript);

  // Script for Body (noscript)
  const bodyScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  bodyScript.appendChild(iframe);
  body.appendChild(bodyScript);

  // Next script
  const additionalScript = document.createElement('script');
  additionalScript.async = true;
  additionalScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID_TWO}`;
  body.appendChild(additionalScript);

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.REACT_APP_GTM_ID_TWO}');
  `;
  body.appendChild(inlineScript);
};

if (process.env.REACT_APP_ENV === 'prod') {
  insertGTMScripts();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

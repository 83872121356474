import React, { useState, useEffect } from 'react';

const DigitalTransformation = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`p-6 ${isMobile ? '' : 'grid-col'}`} style={{ position: 'relative' }}>
      <div className={`bg-white ${isMobile ? 'w-full' : 'max-w-4xl p-6'}`}>
        <h1 className={`${isMobile ? 'text-4xl' : 'text-6xl'} font-light`} style={{ 
            background: 'linear-gradient(to right, #07ABDD, #68B24C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontFamily: 'Roboto', 
            textTransform: 'capitalize', 
            wordWrap: 'break-word',
            fontSize: isMobile ? '32px' : '52px'
          }}>
          <span style={{ fontWeight: 400 }}>enhancing customer experience through </span>
          <span style={{ fontWeight: 800 }}>digital transformation</span>
        </h1>
      </div>
      <div className={`bg-white ${isMobile ? 'flex-col' : 'p-6 flex'}`}>
        <div className={isMobile ? 'w-full mb-4' : 'w-1/2'}></div>
        <div className={isMobile ? 'w-full' : 'w-1/2'}>
          <p style={{ 
              color: '#2D2D2D', 
              fontSize: isMobile ? '18px' : '20px', 
              fontFamily: 'Roboto', 
              fontWeight: 400, 
              lineHeight: isMobile ? '28px' : '32px', 
              wordWrap: 'break-word' 
            }}>
            In today’s digital age, customer experience is paramount. Our solutions are designed to elevate your customer interactions through leveraging data and advanced technologies, we enhance every touchpoint of your customer’s journey, ensuring satisfaction and loyalty. Through Digital Transformation Technologies, we deploy cutting-edge technologies to streamline operations, improve customer interactions, and boost overall efficiency.
          </p>
        </div>
      </div>
      {!isMobile && (
        <div style={{
          position: 'absolute',
          top: '10%',
          right: '-10%',
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          border: '6px solid #68B24C',
          opacity: '0.2'
        }}></div>
      )}
    </div>
  );
};

export default DigitalTransformation;

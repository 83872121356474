import React, { useState } from 'react';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import Arrow from '../../../../assets/svg/Arrow 2.svg';
import { NavBar } from '../../../NavBar';

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = JSON.stringify(formData);
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/activity/formSubmit`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
    } catch (err) {
    } finally {
      setLoading(false);
      setFormData({ fullName: '', email: '', message: '' });
    }
  };

  return (
    <div
      style={{
        fontFamily: 'Roboto, sans-serif',
        padding: '20px',
        maxWidth: '1000px', // Added maxWidth for desktop view
        margin: '0 auto', // Center content
      }}
    >
      <h2
        className="text-4xl font-semibold mb-4"
        style={{
          fontFamily: 'Roboto',
          fontSize: '56px',
          fontWeight: 400,
          lineHeight: '65.63px',
          textAlign: 'left',
          marginBottom: '20px',
          '@media (max-width: 768px)': {
            fontSize: '32px', // Smaller font size for mobile view
            textAlign: 'center', // Center text for mobile view
          }
        }}
      >
        Get in touch with us
      </h2>
      <p className="text-xl mb-8" style={{
        marginBottom: '20px',
        '@media (max-width: 768px)': {
          textAlign: 'center', // Center text for mobile view
        }
      }}>
        Get a quote today!
      </p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="fullName" className="sr-only">
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-600 bg-[#11222C] text-white rounded"
            style={{
              width: '100%', // Full width on mobile
              maxWidth: '700px', // Max width on desktop
              height: '48px',
              marginBottom: '16px', // Add margin for spacing
            }}
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-600 bg-[#11222C] text-white rounded"
            style={{
              width: '100%', // Full width on mobile
              maxWidth: '700px', // Max width on desktop
              height: '48px',
              marginBottom: '16px', // Add margin for spacing
            }}
            required
          />
        </div>
        <div>
          <label htmlFor="message" className="sr-only">
            Your message
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Your message"
            value={formData.message}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-600 bg-[#11222C] text-white rounded"
            style={{
              width: '100%', // Full width on mobile
              maxWidth: '700px', // Max width on desktop
              height: '147px',
              marginBottom: '16px', // Add margin for spacing
            }}
            required
          />
        </div>
        <div>
          {!loading ? (
            <button
              type="submit"
              className="px-6 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-300 transition-colors flex items-center"
              style={{
                fontSize: '16px',
                '@media (max-width: 768px)': {
                  fontSize: '14px', // Smaller font size for mobile
                }
              }}
            >
              Send Message
              <img src={Arrow} className="ml-2 w-4 h-auto" />
            </button>
          ) : (
            <Loader />
          )}
        </div>
      </form>
    </div>
  );
};

export default EnquiryForm;

// ./src/components/Services.jsx

import React from 'react'
import Banner from './Servicespage/Banner'
import Believe from './Servicespage/Believe'
import { NavBar } from './NavBar'
import ServicesPage from './Servicespage/Services'
import Section from './Servicespage/3D'
import Footer from './ui/Footer/Footer'
import Faq from './Faq/ServiceFaq.jsx'

const Services = () => {
  return (
    <div>
        <NavBar/>
      <Banner/>
      <ServicesPage/>
      <Section/>
      <Believe/>
      <Faq/>
      <Footer/>
    </div>
  )
}

export default Services

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import citylights from '../../assets/PNG/citylights.png';

const BlogCard = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Fetch only trending blogs
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/content?type=blog&isTrending=true`);
        
        // Ensure only trending blogs are processed
        const blogData = response.data.data.map(blog => ({
          date: new Date(blog.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }),
          readTime: '15 min read', // Placeholder for read time
          tag: 'Trending', // Assuming all fetched blogs are trending
          id: blog._id,
          title: blog.heading,
          description: blog.subheading,
          image: blog.pictureUrl ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/assets?fileName=${blog.pictureUrl}` : citylights, // Fallback image if URL is missing
        }));
        
        setBlogs(blogData.slice(0, 4)); // Limit to 4 items
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % blogs.length);
    }, 8000);

    return () => clearInterval(timer); // Cleanup the timer
  }, [blogs.length]);

  const content = blogs.length > 0 ? blogs[currentIndex] : null;

  return (
    <div className="py-8 mt-32">
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h1 className='ml-36 text-start bg-gradient-to-r from-blue-400 to-green-400 bg-clip-text text-transparent'
          style={{
            width: '50%',
            fontSize: '52px',
            fontFamily: 'Roboto',
            fontWeight: '600',
            lineHeight: '60px',
            wordWrap: 'break-word',
          }}
        >
          Stay Updated with Our <br /> Latest News
        </h1>
      </div>

      <div
        className="max-w-5xl mx-auto bg-white rounded-xl overflow-hidden border border-gray-200"
        style={{
          width: '800px',
          height: '400px',
          boxShadow: '0px 4px 12px rgba(43, 62, 72, 0.10)',
          borderRadius: '10px',
          borderColor: 'rgba(43, 62, 72, 0.20)',
        }}
      >
        <div className="flex" style={{ height: '100%' }}>
          <div className="w-1/2 h-full">
            <img
              className="h-full w-full object-cover"
              src={content ? content.image : citylights}
              alt="Blog"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="p-8 w-1/2 h-full" style={{ overflow: 'hidden' }}>
            <div className="flex items-center mb-4" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <span
                className="text-sm"
                style={{
                  color: '#07ABDD',
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                }}
              >
                {content ? content.date : 'Loading date...'}
              </span>
              <span
                className="mx-1"
                style={{
                  color: '#11222C',
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                }}
              >
                •
              </span>
              <span
                style={{
                  color: '#2B3E48',
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                }}
              >
                {content ? content.readTime : 'Loading read time...'}
              </span>
              <span
                className="ml-auto px-2 py-1 bg-gradient-to-r from-blue-400 to-green-400 text-white text-xs font-semibold rounded"
                style={{
                  color: 'white',
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                }}
              >
                {content ? content.tag : 'Loading tag...'}
              </span>
            </div>
            <h2
              className="text-lg leading-tight font-medium"
              style={{
                color: '#2B3E48',
                fontSize: '24px',
                fontFamily: 'Roboto',
                fontWeight: '400',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {content ? content.title : 'Loading title...'}
            </h2>
            <p
              className="mt-4"
              style={{
                color: '#11222C',
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: '400',
                lineHeight: '20px',
                maxHeight: '80px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {content ? content.description : 'Loading description...'}
            </p>
            <a
              href={`/News-&-Insights/read-more/${content ? content.id : 'Loading id...'}/${content ? content.title : 'Loading title...'}`}
              className="mt-36 inline-flex items-center px-3 py-2 text-sm font-medium leading-5 text-blue-500 bg-transparent border border-blue-500 rounded-lg hover:bg-blue-500 hover:text-blue focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition ease-in-out duration-150"
            >
              Read More
              <svg
                className="ml-2 -mr-1 h-4 w-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 4.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 10H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;

import React from 'react'
import BlogCard from './Newspage/BlogCard'
import { NavBar } from './NavBar'
import All from './Newspage/All'
import Checkout from './Newspage/CheckOut'
import Article from './Newspage/Article'
import Footer from './ui/Footer/Footer'

const News = () => {
  return (
    <div>
      <NavBar/>
      <BlogCard/>
      <All/>
      <Footer/>
    </div>
  )
}

export default News


import React from 'react'
import HeroSection from './Hero'
import Hero1 from './Hero1'
import Hero2 from './Hero2'
import img1 from '../../assets/PNG/plan.png'
import img2 from '../../assets/PNG/Imapcts.png'
import img3 from '../../assets/PNG/team.png'


const MainHero = () => {
  return (
    <div>
        <HeroSection/>
        <div className="w-full">
        <img src={img1} alt="Plan" className="w-full block" />
      </div>
        <Hero1/>
        <div className="w-full">
        <img src={img2} alt="Impacts" className="w-full block" />
      </div>
        <Hero2/>
        <div className="w-full">
        <img src={img3} alt="team" className="w-full block" />
      </div>
      
    </div>
  )
}

export default MainHero

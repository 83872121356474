import React, { useState } from 'react';
import { FaLinkedin } from 'react-icons/fa';
import Akhil from '../../assets/PNG/Team/Akhil.jpg'
import Kavya from '../../assets/PNG/Team/Kavya.png'
import Shahul from '../../assets/PNG/Team/Shahul.jpg'
import Vinayak from '../../assets/PNG/Team/Vinayak.jpg'
import Saurav from '../../assets/PNG/Team/saurav.jpg'
import Sushant from '../../assets/PNG/Team/Sushant.jpg'
import Arun from  '../../assets/PNG/Team/Arun.jpg'
import Purvi from '../../assets/PNG/Team/Purvi.jpeg'
import Monika from '../../assets/PNG/Team/Monika.jpg'

const teamMembers = [
  {
    name: 'Akhil Padmakumar',
    designation: 'Managing Director',
    linkedin: 'https://www.linkedin.com/in/akhil-padmakumar-80b859199/',
    image: Akhil,
  },
  // {
  //   name: 'Kavya A S',
  //   designation: 'Chief Executive Officer',
  //   linkedin: 'https://www.linkedin.com/in/kavya-a-s-6b3959147/',
  //   image: Kavya,
  // },
  {
    name: 'Shahul Gasnikkhan',
    designation: 'Chief Marketing Officer',
    linkedin: 'https://www.linkedin.com/in/shahul-gasnikhan/',
    image: Shahul,
  },
  {
    name: 'Vinayak Sasi',
    designation: 'HR Manager',
    linkedin: 'https://www.linkedin.com/in/vinayak-sasi-b30057179/',
    image: Vinayak,
  },
  {
    name: 'Saurav Jha',
    designation: 'Senior Software Developer',
    linkedin: 'https://www.linkedin.com/in/varuask/',
    image: Saurav,
  },
  {
    name: 'Sushant Kumar',
    designation: 'Software Developer',
    linkedin: 'https://www.linkedin.com/in/sushant-kumar-a9aa921a9/',
    image: Sushant,
  },
  {
    name: 'Arun K V',
    designation: 'Data Science Engineer',
    linkedin: 'https://www.linkedin.com/in/arun-k-v-740548231',
    image: Arun,
  },
  {
    name: 'Purvi Sharma',
    designation: 'UI/UX Designer',
    linkedin: 'https://www.linkedin.com/in/purvi-sharma-2857301b6/',
    image: Purvi,
  },
  {
    name: 'Monika Saha',
    designation: 'SEO/Content Specialist',
    linkedin: 'https://www.linkedin.com/in/monikasaha303/',
    image: Monika,
  },
  {
    name: 'Stranger',
    designation: 'With Great Skills',
    linkedin: '',
    image: '', 
  },
];

const App = () => {
  const [showCareers, setShowCareers] = useState(false);

  const handleSeeCareersClick = () => {
    setShowCareers(!showCareers);
  };

  return (
    <div className="flex flex-col py-12 px-12">
      <div className="grid grid-cols-3 gap-6">
        {teamMembers.slice(0, 8).map((member, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className="w-48 h-48 rounded-full flex items-center justify-center text-white overflow-hidden"
            >
              <img src={member.image} alt={member.name} className="w-full h-full object-cover"/>
            </div>
            <h3 className="mt-4 text-center text-[#11222C] text-[20px] font-roboto font-semibold break-words">
              {member.name}
            </h3>
            <p className="text-center text-[#11222C] text-[20px] font-roboto font-normal break-words">
              {member.designation}
            </p>
            <a href={member.linkedin} className="mt-2">
              <FaLinkedin className="text-blue-700" />
            </a>
          </div>
        ))}
        <div className="col-span-3 flex flex-row items-center justify-center gap-6">
          <div className="flex flex-col items-center">
            <div
              className="w-48 h-48 rounded-full flex items-center justify-center text-white overflow-hidden"
            >
              <img 
                src={teamMembers[8].image} 
                className="w-full h-full object-cover bg-[#d9d9d9]"
              />
            </div>
            <h3 className="mt-4 text-center text-[#11222C] text-[20px] font-roboto font-semibold break-words">
              {teamMembers[8].name}
            </h3>
            <p className="text-center text-[#11222C] text-[20px] font-roboto font-normal break-words">
              {teamMembers[8].designation}
            </p>
            {teamMembers[8].linkedin && (
              <a href={teamMembers[9].linkedin} className="mt-2">
                <FaLinkedin className="text-blue-700" />
              </a>
            )}
          </div>
          <div className="flex flex-col items-center text-center">
            <p className="text-[#2B3E48] text-[32px] font-roboto font-normal break-words">
              Is this you?<br />It definitely could be!<br />Want to join the team?
            </p>
            <button 
              onClick={handleSeeCareersClick} 
              style={{ 
                background: '#2B3E48', 
                borderRadius: '29px', 
                marginTop: '20px', 
                color: 'white', 
                padding: '10px 20px', 
                border: 'none', 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              See Careers
              <span style={{ marginLeft: '10px', fontSize: '16px' }}>→</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;

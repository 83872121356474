import React from 'react';
import fbIcon from '../../../assets/PNG/social-icons/fb.png';
import linkedinIcon from '../../../assets/PNG/social-icons/linkedin.png';
import instaIcon from '../../../assets/PNG/social-icons/insta.png';


const Footer = () => {
  return (
    <>
    <footer className="text-white py-8" style={{ fontFamily: 'Roboto', background: '#11222C', color: 'white' }}>
      <div className="container">
        <div className="max-w-7xl mx-auto flex flex-wrap justify-between space-x-8">
          
          {/* Follow Us Section */}
          <div className="flex flex-col gap-2">
            <div style={{  background: '#07ABDD', borderRadius: '30px' }}></div>
            <div className="flex items-center mb-2">
              <div className="bg-blue-500 w-4 h-4 rounded-full mr-2"></div>
              <span className="text-sm font-medium">Follow us</span>
            </div>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/profile.php?id=61561715193680" className="text-gray-300 no-underline">
                <img src={fbIcon} alt="Facebook" />
              </a>
              <a href="https://www.linkedin.com/company/pems-digital-technologies-pvt-ltd/" className="text-gray-300 no-underline">
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
              <a href="https://www.instagram.com/pems_digital/" className="text-gray-300 no-underline">
                <img src={instaIcon} alt="Instagram" />
              </a>
            </div>
          </div>

          {/* Quick Links Section */}
          <div className="flex flex-col space-y-2">
            <div className="font-bold text-sm">Quick Links</div>
            <div className="flex flex-col space-y-1">
              <a href="/" className="text-gray-300 no-underline hover:underline">About Us</a>
              <a href="/News-&-Insights" className="text-gray-300 no-underline hover:underline">Blogs</a>
              <a href="/Careers" className="text-gray-300 no-underline hover:underline">Careers</a>
              <a href="/services" className="text-gray-300 no-underline hover:underline">Services</a>
              <a href="/Products" className="text-gray-300 no-underline hover:underline">Products</a>
              <a href="/Company" className="text-gray-300 no-underline hover:underline">Meet the Team</a>
            </div>
          </div>

          {/* Second Quick Links Section */}
          <div className="flex flex-col space-y-6 ml-4"> {/* Adjusted margin-left */}
            <div className="font-bold text-sm"></div>
            <div className="flex flex-col space-y-1">
              <a href="/News-&-Insights" className="text-gray-300 no-underline hover:underline">News & Insights</a>
              <a href="/Privacy-Policy" className="text-gray-300 no-underline hover:underline">Privacy</a>
              <a href="#" className="text-gray-300 no-underline hover:underline">Terms and Conditions</a>
              <a href="#" className="text-gray-300 no-underline hover:underline">Manage Cookies</a>
            </div>
          </div>

          {/* Contact Us Section */}
          <div className="flex flex-col space-y-2">
            <div className="font-bold text-sm">Contact Us</div>
            <div className="flex flex-col space-y-1">
              <a href="mailto:akhil@pemsdigital.com" className="text-gray-300 no-underline">
                akhil@pemsdigital.com
              </a>
              <span>+91 9446220857</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;

import React from 'react';

const Info = () => {
  return (
    <div className="p-12">
      <div className="bg-white py-12 px-6 flex justify-start">
        <div className="w-2/3 space-y-6">
          <p className="text-lg text-[#11222C] text-[16px] font-roboto font-normal leading-[26px] break-words">
            <span className="font-bold">
              • PEMS Digital is dedicated to transforming businesses through cutting-edge digital solutions. By leveraging the latest technology and industry best practices, our team delivers innovative and customized strategies that drive growth and efficiency. With a focus on understanding each client's unique needs, PEMS Digital creates tailored solutions that align with business objectives and maximize impact. Our commitment to excellence and continuous improvement ensures that clients remain at the forefront of their industries, achieving success in an ever-evolving digital landscape.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;

import React from 'react'
import Sectors from './IndustriesPage/SecondPage/Sectors'
import { NavBar } from './NavBar'
import Banner from './IndustriesPage/SecondPage/Banner'
import Footer from './ui/Footer/Footer'

const IndustriesViewAll = () => {
  return (
    <div>
      <NavBar/>
      <Banner/>
      <Sectors/>
      <Footer/>
    </div>
  )
}

export default IndustriesViewAll

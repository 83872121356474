import React from 'react';

const ProjectNeeds = () => {
  return (
    <div className="flex items-center justify-center py-24" style={{ backgroundColor: '#11222C' }}>
      <div className="text-center max-w-6xl ">
        <h1 className="text-white text-5xl md:text-5xl mb-4" style={{ fontFamily: 'Roboto', wordWrap: 'break-word', fontSize:'52px', fontWeight:'600px' }}>
          One Single-Platform for All Your Project Needs
        </h1>
        <div className='space y-6'></div>
        <p className="text-white text-base md:text-lg font-light leading-7 mb-6" style={{ fontFamily: 'Roboto', wordWrap: 'break-word', fontSize:'18px', fontWeight:'400px', lineHeight: '26px' }}>
          Introducing our specialized Document Management Software designed for the construction industry. At PEMS Digital, we recognize the critical importance of efficient document handling in project management. Our software provides a tailored solution for organizing, sharing, and securing project documents such as blueprints and compliance forms. Designed to streamline collaboration and enhance productivity, our system supports construction teams in maintaining document accuracy and efficiency throughout all project phases.
        </p>
        <div style={{ paddingLeft: '18px', paddingRight: '18px', paddingTop: '10px', paddingBottom: '10px', background: '#07ABDD', borderRadius: '45px', overflow: 'hidden', justifyContent: 'flex-start', alignItems: 'center', gap: '12px', display: 'inline-flex', cursor: 'pointer' }}>
          <div style={{ textAlign: 'center', color: '#070557', fontSize: '16px', fontFamily: 'Roboto', fontWeight: '600', lineHeight: '20px', wordWrap: 'break-word' }}>
            Try for Free
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectNeeds;

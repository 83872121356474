import React, { useRef, useEffect } from 'react';
import ResourceCard from './ResourceCard'; // Adjust the path based on your file structure

const Resources = ({ resources, scrollLeft }) => {
  return (
    <div className="overflow-hidden">
      <div
        className="flex space-x-4 transition-transform duration-300"
        style={{ transform: `translateX(-${scrollLeft}px)` }}
      >
        {resources.map((resource) => (
          <ResourceCard key={resource._id} {...resource} />
        ))}
      </div>
    </div>
  );
};

export default Resources;

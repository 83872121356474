// import React, { useState } from 'react';
// import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
// import SvgImage from '../../assets/PNG/FAQ.png'; // Make sure to adjust the path accordingly

// const FAQs = () => {
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [question, setQuestion] = useState('');
//   const [searchQuery, setSearchQuery] = useState(''); // State for search query

//   const faqs = [
//     {
//       question: 'What is Document Management System (DMS)?',
//       answer: 'Document Management System (DMS) is a specialized tool designed to manage and organize all project-related documents, ensuring easy access, version control, and secure storage.',
//     },
//     {
//       question: 'How can our Document Management System (DMS) benefit my construction project?',
//       answer: 'The Document Management System (DMS) improves efficiency by centralizing document storage, enabling quick access to important files, and reducing the risk of document loss or misplacement.',
//     },
//     {
//       question: 'Is the Document Management System (DMS) compatible with other construction software?',
//       answer: 'Yes, DMS is designed to integrate seamlessly with other construction software, enhancing your existing workflows without disruption.',
//     },
//     {
//       question: 'Can I access the Document Management System remotely?',
//       answer: 'Absolutely, the Document Management System is cloud-based, allowing you to access and manage your documents from anywhere, at any time, using any device.',
//     },
//     {
//       question: 'What security features does our Document Management System offer?',
//       answer: 'The Document Management System includes robust security features such as user authentication, access controls, and encrypted data storage to ensure the safety of your documents.',
//     },
//   ];

//   const handleToggle = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   const handleQuestionSubmit = () => {
//     console.log("Question submitted:", question);
//     // Handle the question submission logic here
//     setQuestion('');
//   };

//   const filteredFaqs = faqs.filter((faq) =>
//     faq.question.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <div className="container mx-auto px-4 py-16 flex">
//       <div className="w-1/2">
//         <img src={SvgImage} alt="FAQs Illustration" className="w-full h-auto" />
//       </div>
//       <div className="w-1/2">
//         <h1 className="text-gray-800 text-4xl font-semibold leading-tight mb-6 text-center">FAQs</h1>
//         <div className="max-w-2xl mx-auto">
//           <div className="mb-4">
//             <input
//               type="text"
//               placeholder="Search a query"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//               className="w-full px-4 py-2 border rounded shadow-sm"
//             />
//           </div>
//           {filteredFaqs.map((faq, index) => (
//             <div key={index} className="mb-4">
//               <button
//                 onClick={() => handleToggle(index)}
//                 className="w-full text-left px-4 py-2 border rounded shadow-sm focus:outline-none flex justify-between items-center"
//               >
//                 <span className="text-gray-700 text-base">{faq.question}</span>
//                 {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
//               </button>
//               {activeIndex === index && (
//                 <div className="text-gray-700 text-base px-4 py-2 border rounded shadow-sm mt-2">
//                   {faq.answer}
//                 </div>
//               )}
//             </div>
//           ))}
//           <div className="flex justify-between items-center mt-6">
//             <div className="flex flex-1 mr-2">
//               <input
//                 type="text"
//                 value={question}
//                 onChange={(e) => setQuestion(e.target.value)}
//                 placeholder="Ask a Question"
//                 className="w-full px-4 py-2 border rounded-l shadow-sm"
//               />
//               <button
//                 onClick={handleQuestionSubmit}
//                 className="px-4 py-2 bg-gray-800 text-white rounded-r"
//               >
//                 Submit
//               </button>
//             </div>
//             <a href="/Contact" className="text-gray-700 text-sm underline ml-4">Contact Us</a>
//             <a href="/Privacy-Policy" className="text-gray-700 text-sm underline ml-4">Privacy Policy</a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FAQs;







import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import SvgImage from '../../assets/PNG/FAQ.png'; // Make sure to adjust the path accordingly

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]); // Initialize as an empty array
  const [question, setQuestion] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  // Fetch FAQs from backend
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/faq?category=products`);
        console.log('Fetched FAQs:', response.data); // Debug: Check the API response
        if (response.data && Array.isArray(response.data.data)) {
          setFaqs(response.data.data); // Update state if the response is an array
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };
    fetchFaqs();
  }, []);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleQuestionSubmit = async () => {
    try {
      await axios.post('https://website-backend-ocng.onrender.com/api/v1/activity/questionSubmit', { question});
      console.log("Question submitted:", question);
      setQuestion('');
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };

  const filteredFaqs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-16 flex">
      <div className="w-1/2">
        <img src={SvgImage} alt="FAQs Illustration" className="w-full h-auto" />
      </div>
      <div className="w-1/2">
        <h1 className="text-gray-800 text-4xl font-semibold leading-tight mb-6 text-center">FAQs</h1>
        <div className="max-w-2xl mx-auto">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search a query"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 border rounded shadow-sm"
            />
          </div>
          {filteredFaqs.length > 0 ? (
            filteredFaqs.map((faq, index) => (
              <div key={index} className="mb-4">
                <button
                  onClick={() => handleToggle(index)}
                  className="w-full text-left px-4 py-2 border rounded shadow-sm focus:outline-none flex justify-between items-center"
                >
                  <span className="text-gray-700 text-base">{faq.question}</span>
                  {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {activeIndex === index && (
                  <div className="text-gray-700 text-base px-4 py-2 border rounded shadow-sm mt-2">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-center text-gray-700">No FAQs available</div>
          )}
          <div className="flex justify-between items-center mt-6">
            <div className="flex flex-1 mr-2">
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Ask a Question"
                className="w-full px-4 py-2 border rounded-l shadow-sm"
              />
              <button
                onClick={handleQuestionSubmit}
                className="px-4 py-2 bg-gray-800 text-white rounded-r"
              >
                Submit
              </button>
            </div>
            <a href="/Contact" className="text-gray-700 text-sm underline ml-4">Contact Us</a>
            <a href="/Privacy-Policy" className="text-gray-700 text-sm underline ml-4">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;


import React from 'react';
import gif1 from '../../assets/GIF/Version.gif'
import gif2 from '../../assets/GIF/Access.gif'
import gif3 from '../../assets/GIF/Mobile.gif'
import gif4 from '../../assets/GIF/Notifications.gif'
import gif5 from '../../assets/GIF/Advanced.gif'

const Building = () => {
  return (
    <div className='p-6 relative h-[800px]'>
      <div className="mb-12"></div>
      <div className="text-center text-[#2B3E48] text-[52px] font-roboto font-semibold break-words">
        Build Smarter, Not Harder
      </div>
      <p className="mb-12 text-[#11222C] text-[18px] font-roboto font-normal leading-[26px] break-words text-center mb-8">
        Key features of our Construction Project Document Management Software
      </p>
      <div className="flex flex-col items-center justify-center">
        <div className="max-w-5xl p-10 bg-white rounded-lg w-full h-full rounded-2xl" style={{ border: '1px solid #0B0B0B' }}>

          <div className="grid gap-8 sm:grid-cols-2">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <img src={gif1} alt="Version Control" className="w-16 h-16" />
              </div>
              <div>
                <h3 className="text-[#2B3E48] text-[24px] font-roboto font-normal break-words">Version Control</h3>
                <p className="text-[#11222C] text-[14px] font-roboto font-medium leading-[26px] break-words">
                  Keep track of document versions, ensuring that you always have access to the most up-to-date information.
                </p>
                <div className="mt-2" style={{ border: '1px solid #0B0B0B' }}></div>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <img src={gif2} alt="Access Control" className="w-16 h-16" />
              </div>
              <div>
                <h3 className="text-[#2B3E48] text-[24px] font-roboto font-normal break-words">Access Control</h3>
                <p className="text-[#11222C] text-[14px] font-roboto font-medium leading-[26px] break-words">
                  Define user roles and permissions to ensure that only authorized personnel can access specific documents.
                </p>
                <div className="mt-2" style={{ border: '1px solid #0B0B0B' }}></div>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <img src={gif3} alt="Mobile Accessibility" className="w-16 h-16" />
              </div>
              <div>
                <h3 className="text-[#2B3E48] text-[24px] font-roboto font-normal break-words">Mobile Accessibility</h3>
                <p className="text-[#11222C] text-[14px] font-roboto font-medium leading-[26px] break-words">
                  Access your documents anytime, anywhere with our mobile-friendly platform, perfect for on-site project management.
                </p>
                <div className="mt-2" style={{ border: '1px solid #0B0B0B' }}></div>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <img src={gif4} alt="Automated Notifications" className="w-16 h-16" />
              </div>
              <div>
                <h3 className="text-[#2B3E48] text-[24px] font-roboto font-normal break-words">Automated Notifications</h3>
                <p className="text-[#11222C] text-[14px] font-roboto font-medium leading-[26px] break-words">
                  Stay informed with automated notifications for document updates, approvals, and deadlines.
                </p>
                <div className="mt-2" style={{ border: '1px solid #0B0B0B' }}></div>
              </div>
            </div>
            <div className="flex items-start space-x-4 col-span-2 justify-center">
              <div className="flex-shrink-0">
                <img src={gif5} alt="Advanced Search Functionality" className="w-16 h-16" />
              </div>
              <div>
                <h3 className="text-[#2B3E48] text-[24px] font-roboto font-normal break-words ml-28">Advanced Search Functionality</h3>
                <p className="text-[#11222C] text-[14px] font-roboto font-medium leading-[26px] break-words">
                  Quickly find the documents you need with powerful search capabilities, saving you time and effort.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 text-center">
        <div className="pl-8 pr-8 pt-4 pb-4 bg-[#2B3E48] rounded-full inline-flex items-center justify-center gap-3">
          <div className="text-center text-white text-[16px] font-roboto font-semibold leading-[20px] break-words">Get 90 Day Free Trial</div>
        </div>
      </div>
      <div className="absolute top-60 left-1/2 h-[calc(20%-50px)] transform -translate-x-1/2" style={{ border: '1px solid #0B0B0B' }}></div>
      <div className="absolute bottom-80 left-1/2 h-[calc(20%-50px)] transform -translate-x-1/2" style={{ border: '1px solid #0B0B0B' }}></div>
    </div>
  );
};

export default Building;

import React from 'react';
import fbIcon from '../../../assets/PNG/social-icons/fb.png';
import linkedinIcon from '../../../assets/PNG/social-icons/linkedin.png';
import instaIcon from '../../../assets/PNG/social-icons/insta.png';
import EnquiryForm from '../Forms/EnquiryForm/EnquiryForm90';

const Footer = () => {
  return (
    <>
    <div className="min-h screen bg-[#11222C] text-white py-16 sm:px-12 flex flex-col md:flex-row justify-between">
      <footer
        className="bg-[#11222C] text-white p-8"
        style={{ fontFamily: 'Roboto, sans-serif' }}
      >
        <div className="max-w-7xl mx-auto flex flex-col gap-8">
          {/* Follow Us Section */}
          <div>
            <div className="flex items-center mb-4">
              <div className="bg-blue-500 w-4 h-4 rounded mr-2"></div>
              <span className="text-sm font-medium">Follow us</span>
            </div>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/profile.php?id=61561715193680" className="text-white">
                <img src={fbIcon} alt="Facebook" />
              </a>
              <a href="https://www.linkedin.com/company/pems-digital-technologies-pvt-ltd/" className="text-white">
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
              <a href="https://www.instagram.com/pems_digital/" className="text-white">
                <img src={instaIcon} alt="Instagram" />
              </a>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start sm:gap-8">
            <div className="flex flex-col space-y-2">
              <span className="text-sm font-medium">Quick Links</span>
              <a href="/" className="text-gray-300 no-underline">
                About Us
              </a>
              <a href="/News-&-Insights" className="text-gray-300 no-underline">
                Blogs
              </a>
              <a href="/Careers" className="text-gray-300 no-underline">
                Careers
              </a>
              <a href="/Services" className="text-gray-300 no-underline">
                Services
              </a>
              <a href="/Products" className="text-gray-300 no-underline">
                Products
              </a>
              <a href="/Company" className="text-gray-300 no-underline whitespace-nowrap">
                Meet the Team
              </a>
            </div>
            <div className="flex flex-col space-y-2 mt-4 sm:mt-0">
              <a href="/News-&-Insights" className="text-gray-300 no-underline whitespace-nowrap">
                News & Insights
              </a>
              <a href="/Privacy-Policy" className="text-gray-300 no-underline whitespace-nowrap">
                Privacy
              </a>
              <a href="#" className="text-gray-300 no-underline whitespace-nowrap">
                Terms and Conditions
              </a>
              <a href="#" className="text-gray-300 no-underline whitespace-nowrap">
                Manage Cookies
              </a>
            </div>
          </div>

          {/* Contact Us Section */}
          <div className="flex flex-col space-y-2 mt-16">
            <span className="text-sm font-medium">Contact us</span>
            <a href="mailto:akhil@pemsdigital.com" className="text-gray-300 no-underline">
              akhil@pemsdigital.com
            </a>
            <span>+91 9446220857</span>
          </div>
        </div>
      </footer>
      <EnquiryForm />
    </div>
    </>
  );
};

export default Footer;

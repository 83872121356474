import React from 'react';
import bgimg from '../../assets/PNG/companybanner.png';

const DocumentManagementSystem = () => {
  return (
    <div
      className="min-h-screen bg-cover bg-center text-white flex flex-col items-center font-roboto"
      style={{ backgroundImage: `url(${bgimg})` }}
    >
      <header className="w-full flex justify-between items-center p-5">
        <div className="space-x-4">
        </div>
      </header>

      <main className="flex-grow flex flex-col items-center justify-center ">
        <div className="text-center w-full p-6">
          <h1 className="text-white" style={{ fontSize: '52px', fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>
            Discover PEMS Digital
          </h1>
          <p className="text-white" style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: '400', lineHeight: '26px', wordWrap: 'break-word' }}>
          Explore our commitment to innovative digital solutions driving business<br/> transformation digitally across diverse industries.
          </p>
        </div>
                
        

        {/* <div className="flex space-x-4 mt-8">

        </div> */}
      </main>
    </div>
  );
};

export default DocumentManagementSystem;

import React from 'react';

const DeliveringExcellence = () => {
  return (
    <div className="flex flex-row items-center justify-center p-6">
      <div className="text-start p-4 justify-start">
        <h1 className="text-[90px] font-black text-[#2B3E48] underline tracking-[9px] uppercase leading-[132px] opacity-8">
          Delivering
        </h1>
        <h1 className="text-[90px] font-black text-[#2B3E48] underline tracking-[9px] uppercase leading-[132px] mt-4 opacity-8">
          Excellence
        </h1>
        <h1 className="text-[90px] font-black text-[#2B3E48] underline tracking-[9px] uppercase leading-[132px] mt-4 opacity-8">
          Across
        </h1>
        <h1 className="text-[90px] font-black text-[#2B3E48] underline tracking-[9px] uppercase leading-[132px] mt-4 opacity-8">
          Sectors
        </h1>
      </div>
      <div className="mt-16 p-4">
        <div className="flex items-center">
          <div className='mb-2' style={{ width: '20px', height: '20px', background: '#2B3E48', borderRadius: '50%', marginRight: '10px' }}></div>
          <h2 className="text-xl font-bold text-[#11222C] uppercase">
            This is how we do it
          </h2>
        </div>
        <p className="text-lg text-[#11222C] mt-4 leading-[36px]">
          Our services are designed to simplify and enhance everyday life through monitoring and predictive methods. By envisioning how artificial intelligence can benefit the average person, we have delivered solutions to the public market.
        </p>
        <p className="text-lg text-[#11222C] mt-4 leading-[36px]">
          Infrastructure is a significant source of data, particularly in areas such as water usage, power consumption, waste management, and gas utilisation. By analysing these issues, we can extract valuable insights that can be leveraged for future development.
        </p>
        <p className="text-lg text-[#11222C] mt-4 leading-[36px]">
          Data visualisation and analysis can be implemented in any industry, enabling clients to achieve their vision's full potential and enhance performance throughout the entire lifecycle. Our goal is to improve the lives of people through the power of data and technology.
        </p>
      </div>
    </div>
  );
};

export default DeliveringExcellence;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResearchGIF from '../../assets/GIF/RD.gif';
import SalesGIF from '../../assets/GIF/Sales.gif';
import ProductGIF from '../../assets/GIF/PD.gif';

const Opportunity = () => {
  const navigate = useNavigate();

  const handleOpeningsClick = (category) => {
    navigate(`/Careers/jobs/${category}`);
  };

  return (
    <div style={{ fontFamily: 'Roboto' }}>
      <div className="flex items-center justify-center h-[120vh]">
        <div className="container mx-auto text-center">
          <div
            className="text-4xl font-semibold my-4"
            style={{
              background: "linear-gradient(90deg, #68B24C, #07ABDD)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
            }}
          >
            Career Opportunities
          </div>
          <div className="text-lg text-gray-800 mb-8">
            Explore our current job openings and find the perfect role for you.
          </div>
          <div className="flex flex-wrap justify-around mb-8">
            <div
              className="bg-white rounded-lg shadow-md p-6 m-4 w-80"
              style={{
                background: 'white',
                borderRadius: '10px',
                border: '1px rgba(10.61, 10.61, 10.61, 0.20) solid'
              }}
            >
              <div className="flex justify-center items-center mb-4" style={{ height: '122px' }}>
                <img src={ResearchGIF} alt="Research and Development" style={{ width: '122px', height: '122px' }} />
              </div>
              <div className="text-xl text-[#11222C] font-medium mb-2">Research and Development</div>
              <div className=" mb-4">Innovate and create groundbreaking solutions that shape the future of digital construction.
              </div>
              <button
                onClick={() => handleOpeningsClick('research_and_development')}
                className="inline-flex items-center gap-2 px-4 py-2 text-white rounded-full"
                style={{
                  background: '#2B3E48',
                  color: 'white',
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  lineHeight: '20px',
                  wordWrap: 'break-word',
                  textDecoration: 'none'
                }}
              >
                Openings (1)
              </button>
            </div>
            <div
              className="bg-white rounded-lg shadow-md p-6 m-4 w-80"
              style={{
                background: 'white',
                borderRadius: '10px',
                border: '1px rgba(10.61, 10.61, 10.61, 0.20) solid'
              }}
            >
              <div className="flex justify-center items-center mb-4" style={{ height: '122px' }}>
                <img src={SalesGIF} alt="Sales and Marketing" style={{ width: '122px', height: '122px' }} />
              </div>
              <div className="text-xl text-[#11222C] font-medium mb-2">Sales and Marketing</div>
              <div className=" mb-4">Drive growth and connect with clients through dynamic strategies and compelling campaigns</div>
              <button
                onClick={() => handleOpeningsClick('sales_and_marketing')}
                className="inline-flex items-center gap-2 px-4 py-2 text-white rounded-full"
                style={{
                  background: '#2B3E48',
                  color: 'white',
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  lineHeight: '20px',
                  wordWrap: 'break-word',
                  textDecoration: 'none'
                }}
              >
                Openings (0)
              </button>
            </div>
            <div
              className="bg-white rounded-lg shadow-md p-6 m-4 w-80"
              style={{
                background: 'white',
                borderRadius: '10px',
                border: '1px rgba(10.61, 10.61, 10.61, 0.20) solid'
              }}
            >
              <div className="flex justify-center items-center mb-4" style={{ height: '122px' }}>
                <img src={ProductGIF} alt="Product and Design" style={{ width: '122px', height: '122px' }} />
              </div>
              <div className="text-xl text-[#11222C] font-medium mb-2">Product and Design</div>
              <div className=" mb-4"> Craft intuitive and impactful products that transform the digital landscape with cutting-edge design</div>
              <button
                onClick={() => handleOpeningsClick('product_and_design')}
                className="inline-flex items-center gap-2 px-4 py-2 text-white rounded-full"
                style={{
                  background: '#2B3E48',
                  color: 'white',
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  lineHeight: '20px',
                  wordWrap: 'break-word',
                  textDecoration: 'none'
                }}
              >
                Openings (0)
              </button>
            </div>
          </div>
          <a
            href="#"
            className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-blue-600 to-green-500 text-white rounded-full"
            style={{ textDecoration: 'none' }}
          >
            Explore All Openings
            <div className="w-3.5 h-3.5 border-t-2 border-r-2 border-white transform rotate-45"></div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Opportunity;

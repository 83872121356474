import React from 'react'
import  img from '../../assets/PNG/illustration.png'

const JoinPems = () => {
  return (
    <div>
      <div className="flex h-screen bg-white p-6">
      {/* Left Half: Text Content */}
      <div className="w-1/2 flex items-center justify-center p-8">
        <div>
          <div style={{ color: '#2B3E48', fontSize: '16px', fontFamily: 'Roboto', fontWeight: '600', textTransform: 'uppercase', wordWrap: 'break-word', marginBottom: '16px' }}>
            Join the PEMS Digital Team
          </div>
          <div style={{ color: '#11222C', fontSize: '18px', fontFamily: 'Roboto', fontWeight: '400', lineHeight: '26px', wordWrap: 'break-word', marginBottom: '16px' }}>
            At PEMS Digital, we are passionate about driving digital transformation and innovation. Our team is dedicated to creating cutting-edge solutions that empower businesses to thrive in the digital era. If you're looking to be part of a dynamic, forward-thinking company that values creativity, collaboration, and continuous growth, PEMS Digital is the place for you.
          </div>
          <button className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600">
            Join Us
          </button>
        </div>
      </div>

      {/* Right Half: Image */}
      <div className="w-1/2 flex items-center justify-center p-8">
        <img src={img} alt="Illustration" className="max-w-full h-auto"/>
      </div>
    </div>
    </div>
  )
}

export default JoinPems

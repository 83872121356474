import React from 'react';
import Pic from '../../../assets/PNG/representation.png';

const DigitalisationSection = () => {
  return (
    <>
      <div className="flex justify-center items-center mt-24 bg-white p-6">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center text-start md:text-left">
          <div className="md:w-1/2 p-4">
            <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500" style={{ fontSize: '52px', fontFamily: 'Roboto', fontWeight: '600', lineHeight: '60px', wordWrap: 'break-word' }}>
              Make Digitalisation Available to Everyone
            </h1>
          </div>
          <div className="md:w-1/2 p-4">
            <p className="text-gray-700" style={{ color: '#11222C', fontSize: '16px', fontFamily: 'Roboto', fontWeight: '400', lineHeight: '26px', wordWrap: 'break-word' }}>
              Our versatile and robust solutions cater to a diverse range of industries. Our commitment to driving digital and business transformation ensures that organizations across various sectors can enhance efficiency, streamline operations, and innovate effectively.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-12 px-6">
        <img src={Pic} alt="Digitalisation Representation" className="max-w-[1200] max-h-[850px] rounded-md" />
      </div>
    </>
  );
};

export default DigitalisationSection;

import React, { useState } from 'react';
import axios from 'axios';
import ThankYouCard from '../../ThanksYouCard/ThankYouCard';

const ContactForm = () => {
  const [submitEffect, setSubmitEffect] = useState({
    color: '',
    message: '',
    heading: '',
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: '',
  });
  const [submitStatus, setSubmitStatus] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fullName: formData.name,
      email: formData.email,
      message: formData.message,
      optionals: {
        phoneNumber: formData.phone,
        industry: formData.service,
      },
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/activity/formSubmit`,
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setSubmitEffect((prev) => ({
          ...prev,
          color: '#4caf50',
          message:
            'We have received your Enquiry. Someone from our team will reach out to you shortly.',
          heading: 'Thank You!',
        }));
      })
      .catch((error) => {
        setSubmitEffect((prev) => ({
          ...prev,
          color: '#f2765c',
          message: 'This will soon be fixed.',
          heading: 'Something went wrong',
        }));
        console.error('Error submitting form:', error);
      })
      .finally(() => {
        setSubmitStatus(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          service: '',
          message: '',
        });
      });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 py-12 mt-12">
      <h1
        className="text-4xl font-semibold text-[#2B3E48] mb-6"
        style={{
          fontSize: '52px',
          fontFamily: 'Roboto',
          wordWrap: 'break-word',
        }}
      >
        Get in touch with us
      </h1>
      <p
        className="text-lg text-[#11222C] mb-6"
        style={{
          fontSize: '20px',
          fontFamily: 'Roboto',
          fontWeight: 400,
          wordWrap: 'break-word',
        }}
      >
        Get a quote today!
      </p>
      {!submitStatus ? (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone (optional)
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label
                htmlFor="service"
                className="block text-sm font-medium text-gray-700"
              >
                Products/Services you are interested in
              </label>
              <select
                name="service"
                id="service"
                value={formData.service}
                onChange={handleChange}
                className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Chose from the list</option>
                <option value="BRIX 🧱">BRIX 🧱</option>
                <option value="HRMS App">HRMS App</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              Your message
            </label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              rows="4"
            />
          </div>
          <button
            type="submit"
            className="inline-flex items-center"
            style={{
              paddingLeft: '18px',
              paddingRight: '18px',
              paddingTop: '10px',
              paddingBottom: '10px',
              background: '#2B3E48',
              borderRadius: '45px',
              overflow: 'hidden',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '12px',
              display: 'inline-flex',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                color: 'white',
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 600,
                lineHeight: '20px',
                wordWrap: 'break-word',
              }}
            >
              Send Message
            </div>
          </button>
        </form>
      ) : (
        <ThankYouCard
          color={submitEffect.color}
          message={submitEffect.message}
          heading={submitEffect.heading}
        />
      )}
    </div>
  );
};

export default ContactForm;

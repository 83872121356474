import React from 'react';
import Product1 from '../../assets/img/product1.png';
import Product2 from '../../assets/img/Product2.png';

const Product = ({ title, description, imageSrc, altText, reverse }) => {
  return (
    <div className='p-6'>
    <div className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center p-6 mb-10 w-full`}>
      <div className="md:w-1/2 mb-6 md:mb-0 px-6">
        <img src={imageSrc} alt={altText} className="rounded-lg w-full" />
      </div>
      <div className="md:w-1/2 md:ml-6">
        <div style={{ width: '100%', color: 'white', fontSize: '36px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word' }}>
          {title}
        </div>
        <div style={{ width: '100%', color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word', margin: '16px 0' }}>
          {description}
        </div>
        <div style={{ color: '#07ABDD', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400, textDecoration: 'underline', textTransform: 'uppercase', lineHeight: '20px', wordWrap: 'break-word' }}>
          GET A DEMO
        </div>
      </div>
    </div>
    </div>
  );
};

function App() {
  return (
    <div style={{ fontFamily: 'Roboto' }}>
      <div className="min-h-screen p-8" style={{ background: '#11222C', color: 'white' }}>
        <div className="text-center mb-16">
          <h1 style={{ fontSize: "52px", fontFamily: "Roboto, sans-serif", fontWeight: 600 }}>Here’s the Offer</h1>
        </div>
        <div className="text-center mb-16">

        </div>
        <div className="space-y-16">
          <Product
            title="Streamlined Document Organization:"
            description="Our document management software for the construction industry ensures seamless organization of project documents such as blueprints, contracts, and compliance forms. By centralizing document storage and version control, teams can access the latest information instantly, enhancing efficiency and reducing errors."
            imageSrc={Product1}
            altText="Streamlined Document Organization screenshot"
            reverse
          />
          <Product
            title="Enhanced Collaboration Tools:"
            description="Facilitate real-time collaboration among project teams with advanced sharing and annotation features. Our software fosters communication and teamwork, allowing stakeholders to review and comment on documents within a secure environment. This promotes faster decision-making and improves project coordination."
            imageSrc={Product1}
            altText="Enhanced Collaboration Tools screenshot"
          />
          <Product
            title="Robust Security and Compliance Measures:"
            description="Built-in security protocols and compliance features ensure that sensitive construction documents are protected against unauthorized access and data breaches. With customizable user permissions and audit trails, our software helps construction companies maintain regulatory compliance and mitigate risks effectively."
            imageSrc={Product1}
            altText="Robust Security and Compliance Measures screenshot"
            reverse
          />
        </div>
      </div>
    </div>
  );
}

export default App;

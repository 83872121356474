import React from 'react';

const HeroSection = () => {
  return (
    <div className="flex items-center justify-center py-24 px-6 bg-white">
      <div className="text-center px-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Building towards a better tomorrow</h1>
        <p className="text-lg text-gray-600">
          Welcome to PEMS Digital, where innovation meets excellence. We are a leading provider of digital<br/>
          transformation services, dedicated to empowering businesses across diverse industries. Our mission is to<br/>
          drive meaningful change through cutting-edge technology, strategic insights, and unparalleled expertise.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;

import React, { useState, useEffect } from 'react';
import m3 from '../../assets/GIF/Our belief.gif';
import m1 from '../../assets/GIF/Our mission.gif';
import m2 from '../../assets/GIF/Our vision.gif';
import banner from '../../assets/PNG/businessbanner.png';

const Card = ({ title, content, icon, isMobile }) => (
  <div className={`w-full ${title === "Our Vision" ? "h-auto" : "h-full"} bg-white rounded-lg border border-gray-200 p-6 flex flex-col items-center text-center`}>
    <div className="mb-4">
      <img src={icon} alt={`${title} icon`} className={`h-${isMobile ? '24' : '36'} w-${isMobile ? '24' : '36'}`} style={{ width: isMobile ? '100px' : '130px', height: isMobile ? '100px' : '130px' }} />
    </div>
    <div className={`text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-green-500 ${isMobile ? 'text-4xl' : 'text-5xl'}`} style={{ fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word', textAlign: 'center' }}>
      {title}
    </div>
    <p className={`text-gray-600 ${isMobile ? 'text-sm' : 'text-base'}`}>{content}</p>
  </div>
);

const MissionVisionBelief = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const missionContent = "To empower construction professionals globally by reducing the market entry barrier through digital transformation, driving efficiency, sustainability, and collaboration throughout the building lifecycle.";
  const visionContent = "To be the leading catalyst for a future of construction that is smarter, greener, and more connected, shaping a built environment for a better tomorrow.";
  const beliefContent = "It centers around digital transformation services aimed at revolutionizing the construction and infrastructure sectors. We prioritize harnessing digital technologies to enhance efficiency, safety, and innovation within these industries. Sustainability is a cornerstone of our approach, integrating practices that contribute to a greener future across all our solutions. Emphasizing collaboration and teamwork, we partner closely with clients and stakeholders to achieve mutual objectives and lasting impacts. Customer-centricity drives our solutions, ensuring they are tailored to meet the unique challenges of construction and infrastructure. Upholding integrity and excellence, we maintain the highest standards in our work, delivering reliable, top-quality services and solutions to our clients in every endeavor.";

  return (
    <div style={{ fontFamily: 'Roboto' }}>
      {isMobile ? (
        <>
          <div className="flex flex-col justify-center gap-4 p-4">
            <Card 
              title="Our Mission" 
              content={missionContent} 
              icon={m1} 
              isMobile={isMobile}
            />
            <Card 
              title="Our Vision" 
              content={visionContent} 
              icon={m2} 
              isMobile={isMobile}
            />
          </div>
          <div className="flex flex-col justify-center gap-4 p-4">
            <Card 
              title="Our Belief" 
              content={beliefContent} 
              icon={m3} 
              isMobile={isMobile}
            />
          </div>
          <div className="w-full">
            <img src={banner} alt="Business Banner" className="w-full" />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-center gap-6 p-6">
            <Card 
              title="Our Mission" 
              content={missionContent} 
              icon={m1} 
              isMobile={isMobile}
            />
            <Card 
              title="Our Vision" 
              content={visionContent} 
              icon={m2} 
              isMobile={isMobile}
            />
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-6 p-6">
            <Card 
              title="Our Belief" 
              content={beliefContent} 
              icon={m3} 
              isMobile={isMobile}
            />
          </div>
          <div className="w-full">
            <img src={banner} alt="Business Banner" className="w-full" />
          </div>
        </>
      )}
    </div>
  );
};

export default MissionVisionBelief;

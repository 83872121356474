import React from 'react';
import imgquote from '../../assets/PNG/Quotes.png'

const Apply = () => {
  return (
    <>
    <>
      <div className="flex items-center justify-center h-[278px] bg-gray-800" style={{ fontFamily: 'Roboto', background: '#11222C', color: 'white' }}>
        <div className="text-center text-white px-32">
          <div style={{ width: '100%', textAlign: 'center' }}>
            <span style={{ color: 'white', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 400, lineHeight: '26px', wordWrap: 'break-word' }}>
              Partner with PEMS Digital and take your document management in <br/> construction to the next level. Embrace innovation, enhance collaboration,<br/> and achieve excellence with our state-of-the-art solutions.
            </span>
           </div>
        </div>
        
      </div>
      <div className="mx-auto px-16" style={{ fontFamily: 'Roboto', background: '#11222C' }}>
    <div className="flex flex-wrap justify-between items-center border-t border-gray-600 pt-4 text-sm" style={{ paddingLeft: '10px', paddingRight: '10px' }}></div>
</div>
</>
    </>
  );
};

export default Apply;
